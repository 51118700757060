
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
  AdvancedSearchTypes, GetArea, GetAreaSuccess
} from '../actions/advanced-search.actions';
import { DistributorService } from '../../providers/distributor.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AdvancedSearchEffects {

  @Effect() getAreas$ = this.actions$
    .pipe(ofType(AdvancedSearchTypes.GET_AREAS))
    .pipe(switchMap((action: GetArea) => this.distributorService.getArea(action.searchInput).pipe(
      mergeMap((areas) => observableOf(new GetAreaSuccess(areas))),
      catchError(err => observableOf(new GetAreaSuccess(err))),)
    ));

  constructor(
    private actions$: Actions,
    private distributorService: DistributorService
  ) {
  }

}
