import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts-more-node';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { throttleTime } from 'rxjs/operators';

export function addLabelsAndTooltip(point, noData, ha) {
  if (point.name.toLowerCase() === noData.toLowerCase()) {
    return `<b>${point.name}`;
  } else {
    return `<b>${point.name}</b>: <br>${point.y.toFixed(2)}% (${point.x.toFixed(2)} ${ha})`;
  }
}


@Component({
  selector: 'crop-health',
  template: `<div #chartTarget></div>`
})
export class CropHealthComponent implements OnInit, OnDestroy {
  chart: Highcharts.ChartObject;
  @ViewChild('chartTarget') chartTarget: ElementRef;
  @Input() chartData: any;
  @Input() chartName: string;
  resize$: Observable<any>;
  resizeSubscription: Subscription;
  responsiveOptions = {
    rules: [{
      condition: {
        maxWidth: 400
      },
      chartOptions: {
        chart: {
          height: 300
        }
      }
    },
      {
        condition: {
          minWidth: 401
        },
        chartOptions: {
          chart: {
            height: 350
          }
        }
      }]
  };

  constructor(private translate: TranslateService) {
    this.resize$ = fromEvent(window, 'resize').pipe(throttleTime(300));
  }

  ngOnInit() {
      this.getCharts();
      this.resizeSubscription = this.resize$.subscribe(() => {
        this.getCharts();
      });
  }

  checkWidth(innerWidth) {
    if (innerWidth > 1024) {
      return innerWidth / 3;
    }
    if (innerWidth < 1024 && innerWidth > 600) {
      return innerWidth / 2;
    }
    if (innerWidth < 600) {
      return innerWidth * 0.8;
    }
  }

  getCharts() {
    this.chartData = this.chartData || {};
    let ha = this.translate.instant('LABELS.HA');
    this.chartData.noData = 0; // need dummy data to draw empty pie with 'no data
    this.chartData.noDataArea = 0; // need dummy data to draw empty pie with 'no data
    let noData = this.translate.instant('SHARED.NO_DATA');

    this.chart = Highcharts.chart(this.chartTarget.nativeElement, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        width: this.checkWidth(window.innerWidth),
        backgroundColor: null
      },
      colors: ['#db4437', '#f4b303', '#1fd60e', '#FFFFFF'],
      title: {
        text: this.chartName
      },
      credits: {
        enabled: false
      },
      tooltip: {
        formatter () {
          return addLabelsAndTooltip.call(this, this.point, noData, ha);
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            formatter () {
              return addLabelsAndTooltip.call(this, this.point, noData, ha);
            },
            style: {
              color: 'black'
            },
            connectorColor: 'silver'
          }
        }
      },
      series: [{
        data: [
          { name: this.translate.instant('CHARTS.NO_BIOMASS'), y: this.chartData.noBiomass || null, x: this.chartData.noBiomassArea},
          { name: this.translate.instant('CHARTS.LOW_BIOMASS'), y: this.chartData.lowBiomass || null, x: this.chartData.lowBiomassArea},
          { name: this.translate.instant('CHARTS.HEALTHY_BIOMASS'), y: this.chartData.healthy || null, x: this.chartData.healthyCropArea},
          { name: noData, y: this.displayEmptyData(this.chartData) || null, x: null,
            borderColor: '#595959',
            border: 1}
        ]
      }],
      responsive: this.responsiveOptions
    });
  }

  displayEmptyData(chartData) {
    if (!chartData.noBiomass && !chartData.lowBiomass && !chartData.healthy) {
      return this.chartData.noData = 100;
    }
  }


  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }

}
