
import {take, map, filter, combineLatest} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as json2csv from 'json2csv';
import * as FileSaver from 'file-saver';

import { State } from '../core/store/reducers';
import { NutrientTotalsModel } from '../core/models/nutrient-totals.model';
import { Get, Sort } from '../core/store/actions/nutrients.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './nutrients.component.html',
    styleUrls: ['./nutrients.component.scss']
})
export class NutrientsComponent implements OnInit, OnDestroy {

    headers: Array<any>;

    selected = {};

    loading$: Observable<boolean>;
    nutrients$: Observable<NutrientTotalsModel[]>;

    seasonSubscription: Subscription;
    sortSubscription: Subscription;

    sortColumn: string;
    sortAsc: boolean;

    year: number;
    season: number;

    constructor(
        private store: Store<State>,
        private translate: TranslateService
    ) {}

    ngOnInit() {
      this.getHeaderLabels();
        this.loading$ = this.store.select(state => state.nutrients.loading);

        this.nutrients$ = this.store.select(state => state.nutrients.nutrientsSorted).pipe(combineLatest(
            this.store.select(state => state.search.farmId),
            (nutrients, farmId) => {
                if (farmId === null) {
                    return nutrients;
                }
                let filteredNutrients = [];
                for (let nutrient of nutrients) {
                    let farms = nutrient.farms.filter(farm => farm.id === farmId);
                    if (farms.length !== 0) {
                        filteredNutrients.push(Object.assign({}, nutrient, {
                            farms
                        }));
                    }
                }
                return filteredNutrients;
            }
        ));

        this.seasonSubscription = this.store.select(state => state.season).subscribe((season) => {
            this.updateSeason(season);
        });
        this.sortSubscription = this.store.select(state => state.nutrients.sortColumn).pipe(combineLatest(
            this.store.select(state => state.nutrients.sortAsc),
            (sortColumn, sortAsc) => {
                return { sortColumn, sortAsc };
            }
        )).subscribe((sort) => {
            this.sortColumn = sort.sortColumn;
            this.sortAsc = sort.sortAsc;
        });
    }

    getHeaderLabels() {
      this.translate.get('LABELS').subscribe(labels => {
        this.headers = [
          {name: this.translate.instant(labels.PRODUCT), field: 'name'},
          {name: this.translate.instant(labels.HECTARES), field: 'area', tooltip: true},
          {name: this.translate.instant(labels.PLANNED_AVG_HA), field: 'rate', tooltip: true},
          {name: this.translate.instant(labels.PLANNED_QUANTITY), tooltip: true},
          {name: this.translate.instant(labels.ACTUAL_QUANTITY), field: 'actual_quantity', tooltip: true}
        ];
      });
    }

    ngOnDestroy() {
        this.seasonSubscription.unsubscribe();
    }

    updateSeason(values) {
        this.selected = {};
        this.year = values.year;
        this.season = values.season;
        this.store.dispatch(new Get(this.year, this.season));
    }

    select(index: number) {
        if (this.selected[index]) {
            this.selected[index] = false;
        } else {
            this.selected[index] = true;
        }
    }

    sort(column: string) {
        let ascending = this.sortAsc;
        if (this.sortColumn === column) {
            ascending = !ascending;
        } else {
            ascending = true;
        }
        this.store.dispatch(new Sort(column, ascending));
    }

    export() {
        this.store.select(state => state.nutrients).pipe(
        filter(state => !state.loading),
        map(state => state.nutrients),
        take(1),)
        .subscribe((nutrients) => {
            let flatNutrients = [];
            for (let nutrient of nutrients) {
                for (let farm of nutrient.farms) {
                    flatNutrients.push({
                        product_name: nutrient.product.name,
                        farm_name: farm.name,
                        hectares: farm.area,
                        planned_ave_rate: farm.plannedRate,
                        planned_quantity: farm.plannedQuantity,
                        actual_quantity: farm.actualQuantity
                    });
                }
            }
            let csv = json2csv({
                data: flatNutrients
            });

            let data = new Blob([csv], { type: 'text/csv' });
            FileSaver.saveAs(data, 'nutrients-' + this.year + '-' + this.season + '.csv');
        });
    }
}
