import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AnalysisService } from 'ag-space-common';
import { CGIActionTypes, CGILoadSuccess } from '../actions/cgi.actions';
import { forkJoin } from 'rxjs';
import { map ,  switchMap } from 'rxjs/operators';

@Injectable()
export class CGIEffects {

    @Effect() loadCGI$ = this.actions$
        .pipe(ofType(CGIActionTypes.LOAD))
        .pipe(
            switchMap(() => forkJoin(
                this.analysisService.getCropGrowthModels(),
                this.analysisService.getCropGrowthModelLookup()
            ).pipe(map(([models, lookup]) => new CGILoadSuccess(lookup, models))))
        );

    constructor(
        private actions$: Actions,
        private analysisService: AnalysisService
    ) { }
}
