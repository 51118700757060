
import {map, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { State } from '../store/reducers';
import { Get } from '../store/actions/farm.actions';

@Injectable()
export class FarmGuardService implements CanActivate {

    constructor(private router: Router,  private store: Store<State>) {
    }

    canActivate(route: ActivatedRouteSnapshot) {
        let id = route.params['id'];
        let farmerId =  Number(route.params['farmerId']);

        if (id === undefined) {
            id = route.parent.params['id'];
        }

        this.store.dispatch(new Get(id));

        return this.store.select(state => state.farm).pipe(
            filter(state => {
              if (!state.loading && !state.farm || state.farm && farmerId !== state.farm.farmer.id) {
                this.router.navigate(['/404']);
                return true;
              }
              return !state.loading && state.farm && farmerId === state.farm.farmer.id;
            }),
            map(() => true),);
    }

}
