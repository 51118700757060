import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

const smtpjs = 'https://smtpjs.com/v2/smtp.js';
declare var Email: any;

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  demoForm: FormGroup;
  emailSent: boolean;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.loadSendEmailScript();
    this.demoForm = this.formBuilder.group({
      username: ['', Validators.required],
      tel: ['', Validators.nullValidator],
      email: ['', Validators.email]
    });
  }

  loadSendEmailScript() {
    let node = document.createElement('script');
    node.src = smtpjs;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  requestDemo() {
    const { username, email, tel } = this.demoForm.value;
    Email.send('contact.team.original@gmail.com',
      'contact.team.original@gmail.com',
      `Demo request from ${username}`,
      `Hi! <br> <br> Please, provide me with a demo. <br> My contact details: <br>name: ${username},
           <br>e-mail: ${email},  <br> phone number (optional): ${tel} <br> <br> Thank you`,
      {token: '55cff9aa-141d-494a-8bb2-e671dd41c625'});
    this.demoForm.reset();
    this.showMessage();
  }

  private showMessage() {
    this.emailSent = true;
    setTimeout(() => {
      this.emailSent = false;
    }, 2000);
  }
}
