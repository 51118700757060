import { Action } from '@ngrx/store';
import { type } from './utils';
import { LanguageModel } from 'ag-space-common';

export const SettingsActionTypes = {
    UPDATE_REMEMBERED_LANGUAGE: type('[Settings] Update Site language'),
    RELOAD_USER_LANGUAGE: type('[Settings] Reloaded Site language'),
    UPDATE_SITE_LANGUAGE: type('[Settings] Update the language without storing the preference'),
    GET_AVAILABLE_LANGUAGES: type('[Settings] Get available languages'),
    GET_AVAILABLE_LANGUAGES_SUCCESS: type('[Settings] Get available languages success'),
    GET_AVAILABLE_LANGUAGES_FAILURE: type('[Settings] Get available languages failure'),
    SET_USER_LANGUAGE: type('[Settings] Set backend language for the user'),
    SET_USER_LANGUAGE_SUCCESS: type('[Settings] Set backend language for the user success'),
    SET_USER_LANGUAGE_FAILURE: type('[Settings] Set backend language for the user failure'),
};

export class SettingsUpdateRememberedLanguage implements Action {
    type = SettingsActionTypes.UPDATE_REMEMBERED_LANGUAGE;
    constructor( public language: string) {}
}

export class UpdateSiteLanguage implements Action {
    type = SettingsActionTypes.UPDATE_SITE_LANGUAGE;
    constructor( public language: string, public markTouched = true) { }
}

export class GetAvailableLanguages implements Action {
    type = SettingsActionTypes.GET_AVAILABLE_LANGUAGES;
}

export class GetAvailableLanguagesSuccess implements Action {
    type = SettingsActionTypes.GET_AVAILABLE_LANGUAGES_SUCCESS;
    constructor( public languages: LanguageModel[] ) { }
}

export class GetAvailableLanguagesFailure implements Action {
    type = SettingsActionTypes.GET_AVAILABLE_LANGUAGES_FAILURE;
    constructor( public err: any ) { }
}

export class SetUserLanguage implements Action {
    type = SettingsActionTypes.SET_USER_LANGUAGE;
    constructor( public language: LanguageModel) { }
}

export class SetUserLanguageSuccess implements Action {
    type = SettingsActionTypes.SET_USER_LANGUAGE_SUCCESS;
    constructor( public language: LanguageModel) { }
}

export class SetUserLanguageFailure implements Action {
    type = SettingsActionTypes.SET_USER_LANGUAGE_FAILURE;
    constructor( public response: any) { }
}

export class ReloadSelectedLanguage implements Action {
    type = SettingsActionTypes.RELOAD_USER_LANGUAGE;
}

export type SettingsActions = SettingsUpdateRememberedLanguage | GetAvailableLanguagesSuccess | GetAvailableLanguagesFailure | SetUserLanguage | SetUserLanguageSuccess | SetUserLanguageFailure | UpdateSiteLanguage | ReloadSelectedLanguage;
