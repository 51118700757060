import { Component, HostBinding, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnInit } from '@angular/core';
import { IMyDpOptions } from 'mydatepicker';

import { ImageryService, ImageryInformation, ImageryTypes, AgsMapLegendManager } from 'ag-space-common';
import { Observable } from 'rxjs';
import { map ,  combineLatest } from 'rxjs/operators';
import orderBy from 'lodash-es/orderBy';
import { TranslateService } from '@ngx-translate/core';

export function dateFormat(date: string) {
  return {
    year: parseInt(date.substr(0, 4), 10),
    month: parseInt(date.substr(5, 2), 10),
    day: parseInt(date.substr(8, 2), 10),
    raw: date
  };
}

@Component({
  selector: 'maps-select',
  templateUrl: './maps-select.component.html',
  styleUrls: ['./maps-select.component.scss']
})
export class MapsSelectComponent implements OnChanges, OnInit {
  @HostBinding('class.map--filters') mapClass = true;

  @Input() farmId: number = null;

  @Input() fieldIds: number[];
  @Input() crops: Observable<any> = null;
  @Input() year;
  @Input() season;
  @Input() soilAnalysisError: false;

  @Output() onChange: EventEmitter<ImageryInformation> = new EventEmitter<ImageryInformation>();
  @Output() onRetry: EventEmitter<any> = new EventEmitter();


  type: any = null;
  group: any = null;
  option: any = null;

  loading = true;
  showError = true;
  public loadError = false;
  public imageryTypes = ImageryTypes;

  selectedDate = null;

  isLegendOpen$: Observable<boolean>;
  isLegendKeyPresent$: any;

  defaultDateOptions: IMyDpOptions = {
    width: '120px',
    height: null,
    editableDateField: false,
    sunHighlight: false,
    showTodayBtn: false,
    disableDateRanges: [{ begin: { year: 1, month: 1, day: 1 }, end: { year: 9000, month: 1, day: 1 } }],
    minYear: 2000,
    maxYear: new Date().getFullYear(),
    showClearDateBtn: false,
    dateFormat: 'dd mmm yyyy',
    selectionTxtFontSize: null
  };
  dateOptions: IMyDpOptions;
  layers$: Observable<any[]>;

  showNoImageryNotice = false;
  noImageryNoticeTimeout: any;

  constructor(
    private imageryService: ImageryService,
    private legendManager: AgsMapLegendManager,
    private translate: TranslateService
  ) {
  }

  loadTypes() {
    this.layers$ = this.imageryService.getLayers(this.farmId).pipe(
      combineLatest (this.crops),
      map(([layers , crops]) => {
        this.loadError = false;
        if (crops && crops.length > 0) {
          const options = crops.map(crop => ({ name: crop.displayName, value: crop.value }));
          let groupCGI = layers.find(el => el.type === ImageryTypes.CGI);
          if (!groupCGI) {
            layers.push({
              name: this.translate.instant('SHARED.CROP_GROWTH') + ' (' + this.translate.instant('SHARED.BETA') + ')',
              type: ImageryTypes.CGI,
              optionType: 'select',
              groups: [{
                name: this.translate.instant('SHARED.GROWTH_STAGE'),
                key: 'growthStage',
                options
              }, {
                name: this.translate.instant('SHARED.BIO'),
                key: 'biomass',
                options
              }, {
                name: this.translate.instant('SHARED.LAI'),
                key: 'lai',
                options
              }, {
                name: this.translate.instant('SHARED.GAI'),
                key: 'gai',
                options
              }],
            });
          }
        }
        return orderBy(layers, 'name');
      })
    );
  }

  typeChanged(type) {
    this.type = type;
    if (this.type) {
      this.option = null;
      this.selectedDate = null;
      this.groupChanged(this.type.groups[0]);
    } else {
      this.clear();
    }
  }

  clear() {
    this.type = null;
    this.group = null;
    this.selectedDate = null;
    this.optionChanged(null);
    this.loadError = false;
  }

  groupChanged(group) {
    this.showError = true;
    this.group = group;
    this.loadError = false;
    const options = this.selectedDate && group.options ? this.group.options.find(o => o.date === this.selectedDate.date.raw) : null;

    if (options) {
      this.dateOptions = Object.assign({}, this.defaultDateOptions, {
        enableDays: group.options.map(option => dateFormat(option.date))
      });
      this.clearNoImageryNoticeTimer();
      return this.optionChanged(options);
    }

    if (group && group.options && this.type.optionType === 'date') {

      this.dateOptions = Object.assign({}, this.defaultDateOptions, {
        enableDays: group.options.map(option => dateFormat(option.date))
      });

      if (!this.selectedDate) {
        this.selectedDate = { date: dateFormat(group.options[0].date) };
        this.clearNoImageryNoticeTimer();
        return this.optionChanged(group.options[0]);
      } else {
        this.handleShowNoImageryNotice(); // No data available at this date, warn user
        return this.optionChanged(this.selectedDate.date.raw);
      }
    }

    return this.optionChanged(this.option);
  }

  optionChanged(option) {
    this.showError = true;
    this.option = option;

    this.onChange.emit({
      group: this.group,
      type: this.type,
      option: this.option
    });
  }

  dateChanged(event) {
    const formattedDate = `${event.date.year}-${('0' + event.date.month).slice(-2)}-${('0' + event.date.day).slice(-2)}`;
    this.selectedDate.date.raw = formattedDate;

    const option = this.group.options.find(o => o.date === formattedDate);

    return this.optionChanged(option);
  }

  dismissError() {
    this.showError = false;
  }

  dismissNetworkError() {
    this.loadError = false;
    this.option = null;
    this.optionChanged(this.option);
    // this.cropId = null;
  }

  retry() {
    this.onRetry.emit();
  }

  ngOnInit() {
    this.isLegendOpen$ = this.legendManager.legend$.pipe(map(legend => legend !== null && legend.items.length > 0));
    this.isLegendKeyPresent$ = this.legendManager.legend$.pipe(map(legend => Boolean(legend) && Boolean(legend.key)));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = false;
    if ((changes.hasOwnProperty('farmId') && changes.farmId.currentValue !== null) ||
      changes.hasOwnProperty('year') || changes.hasOwnProperty('season')) {
      this.loadTypes();
      this.clear();
    }
  }

  handleShowNoImageryNotice() {
    this.clearNoImageryNoticeTimer();
    this.showNoImageryNotice = true;
    this.noImageryNoticeTimeout = setTimeout(() => {
      this.clearNoImageryNoticeTimer();
    }, 10000);
  }

  clearNoImageryNoticeTimer() {
    if (this.noImageryNoticeTimeout) {
      this.showNoImageryNotice = false;
      clearInterval(this.noImageryNoticeTimeout);
    }
  }

}
