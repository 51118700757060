export class EnvironmentVariables {
  apiUrl: string;
  scriptUrl: string;
  googleMapsApiKey: string;
  k8sUrl: string;
  kubeUrl: string;
  env: string;
  isMaintenanceMode: boolean;
  contourPath: string;
}

import { Injectable } from '@angular/core';

export let globalVariables: EnvironmentVariables;

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
    static variables: EnvironmentVariables;

    constructor() { }

    static loadConfig(): Promise<void> {
      return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.addEventListener('load', (resp) => {
          if (request.status === 200) {
            try {
              EnvironmentService.variables = JSON.parse(request.responseText);
            } catch (e) {
              reject(e);
            }
            resolve();
          } else {
            reject(request.statusText);
          }
        });
        request.open('GET', '../environments/current.json');
        request.send();
      });
    }

    static getVariables() {
      return EnvironmentService.variables;
    }
}

