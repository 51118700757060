
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap, catchError, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    GetSuccess,
    GetFailure,
    FarmsTypes,
    GetFarmsById, GetFarmsByIdFailure, GetFarmsByIdSuccess, SearchFarms
} from '../actions/farms.actions';
import { DistributorService } from '../../providers/distributor.service';

@Injectable()
export class FarmsEffects {

    @Effect() get$ = this.actions$
        .pipe(ofType(FarmsTypes.GET),
            switchMap(() => this.distributorService.getFarms().pipe(
            mergeMap(farms => observableOf(new GetSuccess(farms))),
            catchError(err => observableOf(new GetFailure(err))),)
        ));
  @Effect() getFarmsByFarmer$ = this.actions$
    .pipe(ofType(FarmsTypes.GET_FARMS_BY_ID),
      switchMap((action: GetFarmsById) => this.distributorService.getFarmsByGrower(action.farmerId, action.year, action.season).pipe(
      mergeMap(farms => observableOf(new GetFarmsByIdSuccess(farms, action.farmerId))),
      catchError(err => observableOf(new GetFarmsByIdFailure(err, action.farmerId))),)
    ));


  @Effect() getSearchedFarmers$ = this.actions$
    .pipe(ofType(FarmsTypes.SEARCH_FARMS),
      switchMap((action: SearchFarms) => this.distributorService.searchFarms(action.searchObject, action.growerId).pipe(
      mergeMap(farms => observableOf(new GetFarmsByIdSuccess(farms,  action.growerId))),
      catchError(err => observableOf(new GetFarmsByIdFailure(err, action.growerId))),)
    ));

    constructor(
        private actions$: Actions,
        private distributorService: DistributorService
    ) {
    }

}
