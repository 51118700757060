import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguagePickerComponent } from './language-picker/language-picker.component';

@NgModule({
  declarations: [LanguagePickerComponent],
  exports: [
    LanguagePickerComponent
  ],
  imports: [
    CommonModule
  ]
})
export class SettingsModule { }
