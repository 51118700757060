import { Injectable } from '@angular/core';
import { ZoneModel } from 'ag-space-common';
import { TranslateService } from '@ngx-translate/core';
import { CropNameService } from './crop-name.service'


@Injectable()
export class CropColorService {
  zones: ZoneModel[];

  UNCROPPED_LAND_CROPID = 146;
  OILSEED_RAPE_WINTER_CROPID = 25;
  OILSEED_RAPE_SPRING_CROPID = 24;
  predefinedColors = {};
  cropsListLegend = [];

  constructor(
    private translate: TranslateService,
    private cropName: CropNameService) {
    this.predefinedColors[this.UNCROPPED_LAND_CROPID] = '#D1DAF4';
    this.predefinedColors[this.OILSEED_RAPE_WINTER_CROPID] = '#F9FE02';
    this.predefinedColors[this.OILSEED_RAPE_SPRING_CROPID] = '#F9FE02';
  }

  colorCrops(crops, zones) {
    if (crops && zones) {
      this.cropsListLegend = [];
      zones.forEach(zone => {
        let crop = crops[zone.fieldId] && crops[zone.fieldId]['assignedCrop'] && crops[zone.fieldId]['assignedCrop'].id ? crops[zone.fieldId]['assignedCrop'] : null,
          cropId = crop ? crop.id : null,
          drools = crops[zone.fieldId] && crops[zone.fieldId]['drools'] ? crops[zone.fieldId]['drools'] : null;
        zone['color'] = crop ? '#' + crop.color : '#fff';
        zone['crop'] = crop;
        let index = this.cropsListLegend.map(cropping => cropping.id).indexOf(cropId);
        if (index < 0) {
          let cropName = '';
          if (crop) {
            cropName = this.cropName.cropName(crop, crop.name, drools);
          } else {
            cropName = this.translate.instant('SHARED.UNALLOCATED');
          }
          this.cropsListLegend.push({
            id: cropId,
            color: zone['color'],
            name: cropName
          });
        }
        return zone;
      });
      return {
        zones: zones,
        cropLegend: this.cropsListLegend
      };
    }
  }
}
