
import {combineLatest, takeUntil} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable ,  Subject } from 'rxjs';
import { State } from '../core/store/reducers';
import { SearchFarmers, SetFarmersSorting, SortTypes } from '../core/store/actions/farmers.actions';
import { GetFarmsById, SearchFarms } from '../core/store/actions/farms.actions';
import { ResetArea, ResetSearchFilterObject, UpdateSearchFilterObject } from '../core/store/actions/advanced-search.actions';
import { DistributorUpdate } from '../core/store/actions/auth.actions';
import { GetSeasons } from '../core/store/actions/season.actions';
import { TranslateService } from '@ngx-translate/core';
import { BaseModel } from 'ag-space-common';
import { ModalService } from '../core/providers/modal.service';
import { AdvancedSearchComponent } from '../shared/components/advanced-search/advanced-search.component';
import { BoundingRectEvent } from '../core/models/bounding-rect-event';
import { SearchFilterModel } from '../core/models/search-filter.model';
import { CatalogueLoad } from '../core/store/actions/catalogue.actions';


@Component({
  templateUrl: './growers.component.html',
  styleUrls: ['./growers.component.scss'],
})

export class GrowersComponent implements OnInit, OnDestroy {
  farmers$: Observable<any>;
  loadingGrowers$: Observable<boolean>;
  loadingFarms$: Observable<boolean>;
  selectedGrower = {
    id: null
  };
  farms$: Observable<any>;
  expandedGrower = [];
  seasons$: Observable<any>;
  years$: Observable<any>;

  distributorId: any;
  searchText = '';
  limitTo = 15;
  limitFarmsTo = 10;
  /**
   * [infiniteScrollDistance]="2" - A number representing how close the bottom of the element must be to the bottom by infinite-scroll is triggered.
     [infiniteScrollThrottle]="50" - the event will be triggered this many milliseconds after the user stops scrolling
   */
  infiniteScrollDistance = 2;
  infiniteScrollThrottle = 50;
  displayCrops = {};
  selectedCrop: any;
  selectedFarmer: any;
  displayCropsForFarmByFarmer = {};
  selectedCropForFarm: any;
  selectedFarm: any;
  selectedYear: number;
  selectedSeason: number;
  searchObject: SearchFilterModel;
  searchFilters: Array<any> = [];
  areaList: BaseModel[];
  ngUnsubscribe: Subject<void> = new Subject();


  constructor(
    private store: Store<State>,
    private translate: TranslateService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.store.select(state => state.auth.distributor).pipe(
      combineLatest(
        this.store.select(state => state.season),
        this.store.select(state => state.advancedSearch.searchObj),
        this.store.select(state => state.auth.distributors),
        (id, season, searchObject, distributors) => ({id, season, searchObject, distributors})
      ),
      takeUntil(this.ngUnsubscribe), )
      .subscribe((data) => {
        this.searchFilters = [];
        this.displayCrops = {};
        this.distributorId = data.id;
        this.searchObject = {...data.searchObject, distributorId: data.id, year: data.season.year, season: data.season.season};
        this.expandedGrower = [];
        this.searchFilters = this.getSearchOptions(this.searchObject);
        this.store.dispatch(new SearchFarmers(this.searchObject));
        const selectedDistributor = data.distributors.find((element) => {
          return Number(element.id) === Number(data.id);
        });
        if (selectedDistributor) {
          this.store.dispatch(new CatalogueLoad(selectedDistributor.cropCatalogueId));
        }
      });
    this.farmers$ = this.store.select(state => state.farmers.farmersSorted);
    this.loadingGrowers$ = this.store.select(state => state.farmers.loading);
    this.loadingFarms$ = this.store.select(state => state.farms.farmsLoading);
    this.seasons$ = this.store.select(state => state.season.seasons);
    this.years$ = this.store.select(state => state.season.years);
    this.store.select(state => state.season.year).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(year => {
        this.selectedYear = Number(year);
        this.expandedGrower = [];
      });
    this.store.select(state => state.season.season).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(season => {
        this.selectedSeason = season;
        this.expandedGrower = [];
      });
    this.store.select(state => state.advancedSearch.areas).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(areas => {
      this.areaList = areas;
    });
  }

  onScroll() {
    this.limitTo += 15;
  }

  /**
   * implement additional on scroll event for nested components
   */
  onScrollAssignedFarms() {
    this.limitFarmsTo += 10;
  }

  getFarms(farmer) {
    this.expandedGrower[farmer.id] = !this.expandedGrower[farmer.id];
    this.selectedGrower.id = farmer.id;
    this.displayCropsForFarmByFarmer[farmer.id] = {};
    if (this.expandedGrower[farmer.id] && !this.searchObject) {
      this.limitFarmsTo = 10;
      this.store.dispatch(new GetFarmsById(farmer.id, this.selectedYear, this.selectedSeason));
    }
    if (this.expandedGrower[farmer.id] && this.searchObject) {
      this.limitFarmsTo = 10;
      this.store.dispatch(new SearchFarms(this.searchObject, farmer.id));
    }
  }

  showFarmsByGrower(farmer) {
    return this.farms$ = this.store.select(state => state.farms.farmsById[farmer.id]);
  }

  ngOnDestroy() {
    this.modalService.close();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.store.dispatch(new ResetSearchFilterObject());
    this.store.dispatch(new ResetArea());
  }

  openAdvancedSearchDialog(e: BoundingRectEvent) {
    this.modalService.toggle( AdvancedSearchComponent, {
      inputs: {},
      outputs: {
        onRequestSubmit: this.modalService.close.bind(this.modalService)
      }
    }, true, e.target);
  }

  selectCrop(crop, farmerId) {
    this.displayCrops[farmerId] = crop;
    this.selectedCrop = crop.id;
    this.selectedFarmer = farmerId;
  }

  displaySelectedCrop(crop, farmer) {
    if (this.displayCrops[farmer.id]) {
      return crop.id === this.displayCrops[farmer.id].id;
    }
  }

  displayAllCrops(farmerId) {
    this.displayCrops[farmerId] = null;
  }

  displayAllCropsForFarm(farmerId, farmId) {
    this.displayCropsForFarmByFarmer[farmerId][farmId] = null;
  }

  displaySelectedCropForFarm(crop, farm, farmerId) {
    if (this.displayCropsForFarmByFarmer[farmerId][farm.id]) {
      return crop.id === this.displayCropsForFarmByFarmer[farmerId][farm.id].id;
    }
  }

  selectCropForFarm(crop, farmId, farmerId) {
    this.displayCropsForFarmByFarmer[farmerId][farmId] = crop;
    this.selectedCropForFarm = crop.id;
    this.selectedFarm = farmId;
  }

  setDistributor(distributor) {
    this.store.dispatch(new DistributorUpdate(distributor));
    this.store.dispatch(new GetSeasons(distributor));
  }

  removeAllSearchFilters() {
    this.searchObject = null;
    this.expandedGrower = [];
    this.store.dispatch(new ResetSearchFilterObject());
  }

  resetArea() {
    this.store.dispatch(new ResetArea());
  }

  getSearchOptions(searchObj) {
    let optionList = [];
    let obj = {...searchObj};
    Object.entries(obj).forEach(([key, value]) => {
      if (!value) { return; };
      if (key !== 'distributorId' && key !== 'year' && key !== 'season' && key !== 'cropId') {
        if (obj[key] && key !== 'locationFilter' && key !== 'displayName' && Object.keys(obj[key]).length) {
          Object.entries(value).forEach(([subObjKey, subObjValue]) => {
            optionList.push({
              name: this.translate.instant(`LABELS.${key.toUpperCase()}_${subObjKey.toUpperCase()}`),
              value: this.getFilterName(subObjValue, key),
              action: {
                [key]: {
                  ...obj[key], [subObjKey]: null
                }
              }
            });
          });
        } else {
          let labels = `LABELS.${key.toUpperCase()}`;
          /** add this 'if' to setup proper label */
          if ( key === 'displayName') {
            labels = `LABELS.CROP`;
          }
          optionList.push({
            name: this.translate.instant(labels),
            value: this.getFilterName(value, key),
            action: {
              [key]: null
            }
          });
        }
      }

    });
    return optionList;
  }

  getFilterName(value, key) {
    if (value && key === 'displayName') {
      return value;
    }
    if (value && key === 'locationFilter') {
      return value.name;
    }
    if (value && key === 'farmSize') {
      return `${value} Ha`;
    }
    if (value && key === 'targetYield') {
      return `${value} T/Ha`;
    }
    return value;
  }

  removeSearchFilter(option) {
    let obj = Object.assign(this.searchObject, option.action);
    if (option.name.toLowerCase() === 'location') {
      this.store.dispatch(new ResetArea());
    }
    if (option.name.toLowerCase() === 'crop') {
      obj.cropId = null;
      this.store.dispatch(new SetFarmersSorting(SortTypes.NONE));
    }
    if (!this.isSearchObjectEmpty(obj)) {
      this.store.dispatch(new UpdateSearchFilterObject(obj));
    } else {
      this.store.dispatch(new ResetSearchFilterObject());
    }
  }

  isSearchObjectEmpty(searchObject) {
    return !searchObject || (!searchObject.cropId && !searchObject.displayName &&
      (!searchObject.locationFilter || !searchObject.locationFilter.id) &&
      ((!searchObject.farmSize && !searchObject.targetYield ) ||
      (!searchObject.farmSize.from && !searchObject.farmSize.to && !searchObject.targetYield.from && !searchObject.targetYield.to)));
  }

}
