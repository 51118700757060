import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AuthState, authReducer } from './auth.reducer';
import { CropsState, cropsReducer } from './crops.reducer';
import { NutrientsState, nutrientsReducer } from './nutrients.reducer';
import { FarmsState, farmsReducer } from './farms.reducer';
import { FarmState, farmReducer } from './farm.reducer';
import { SeasonState, seasonReducer } from './season.reducer';
import { SearchState, searchReducer } from './search.reducer';
import { MapState, mapReducer } from './map.reducer';
import { farmersReducer, FarmersState } from './farmers.reducer';
import { fieldReducer, FieldState } from './field.reducer';
import { observationsReducer, ObservationsState } from './observations.reducer';
import { advancedSearchReducer, AdvancedSearchState } from './advanced-search.reducer';
import { cgiReducer, CGIState } from './cgi.reducer';
import { catalogueReducer,  CatalogueState } from './catalogue.reducer';
import { settingsReducer,  SettingsState } from './settings.reducer';
import * as fromSettings from './settings.reducer';
import { createSelector } from 'reselect';

export interface State {
    auth: AuthState;
    crops: CropsState;
    nutrients: NutrientsState;
    farms: FarmsState;
    farm: FarmState;
    season: SeasonState;
    search: SearchState;
    map: MapState;
    farmers: FarmersState;
    field: FieldState;
    observations: ObservationsState;
    advancedSearch: AdvancedSearchState;
    cgi: CGIState;
    settings: SettingsState;
    catalogue: CatalogueState;
}

export const reducers: ActionReducerMap<State> = {
    auth: authReducer,
    crops: cropsReducer,
    nutrients: nutrientsReducer,
    farms: farmsReducer,
    farm: farmReducer,
    season: seasonReducer,
    search: searchReducer,
    map: mapReducer,
    farmers: farmersReducer,
    field: fieldReducer,
    observations: observationsReducer,
    advancedSearch: advancedSearchReducer,
    cgi: cgiReducer,
    settings: settingsReducer,
    catalogue: catalogueReducer
};

export function localStorageSyncMetaReducer(reducer: ActionReducer<any>): ActionReducer<any, any> {
  try {
    if (window.localStorage) {
      return localStorageSync({
        keys: [
          {
            auth: ['loggedIn', 'distributor', 'user', 'distributors']
          },
          {
            settings: [
              'rememberedLanguage',
              'availableLanguages',
              'currentLanguage'
            ]
          },
          'season'
        ],
        rehydrate: true
      })(reducer);
    }
  } catch (e) {
    console.warn(e);
    return reducer;
  }
}

export const metaReducers: MetaReducer<State>[] = [
    localStorageSyncMetaReducer
];

export default reducers;

/**
 * Settings Selectors
 */
export const getSettingsState = (state: State): SettingsState => state.settings;
export const getCurrentLanguage = createSelector(getSettingsState, fromSettings.getCurrentLanguage);
export const getRememberedLanguage = createSelector(getSettingsState, fromSettings.getRememberedLanguage);
export const getAvailableLanguages = createSelector(getSettingsState, fromSettings.getAvailableLanguages);
export const getLanguagesLoading = createSelector(getSettingsState, fromSettings.getLanguagesLoading);
