import { CropGrowthModel, CropGrowthModelLookup } from 'ag-space-common';
import { CGIActionTypes, CGIActions } from '../actions/cgi.actions';
import * as cgiActions from '../actions/cgi.actions';

export interface CropGrowthModelIndex { [cropId: number]: number; }

export interface CGIState {
  models: CropGrowthModel[];
  lookup: CropGrowthModelLookup[];
  cropModelIndex: CropGrowthModelIndex;
}

export const initialState: CGIState = {
  models: [],
  lookup: [],
  cropModelIndex: {}
};

export function cgiReducer(state: CGIState = initialState, action: CGIActions): CGIState {
  switch (action.type) {

    case CGIActionTypes.LOAD_CGI_SUCCESS: {
      const { lookup, models } = <cgiActions.CGILoadSuccess>action;

      const cropModelIndex = lookup.reduce((acc, item) => {
        acc[item.cropId] = item.modelId;
        return acc;
      }, {});

      return {
        ...state,
        models,
        lookup,
        cropModelIndex
      };
    }

    case CGIActionTypes.LOAD_CGI_FAILURE: {
      return initialState;
    }

    default:
      return state;
  }
}
