import { Action } from '@ngrx/store';
import * as сatalogueActions from '../actions/catalogue.actions';
import { CatalogueActionTypes } from '../actions/catalogue.actions';

export type Action = сatalogueActions.CatalogueActions;

export interface CatalogueState {
  id: number;
  loading: boolean;
  error: string;
  catalogue: []
}

const defaultState: CatalogueState = {
  id: null,
  loading: false,
  error: null,
  catalogue: []
};

export function catalogueReducer(state: CatalogueState = defaultState, action: Action): CatalogueState {
  switch (action.type) {
    case CatalogueActionTypes.GET_CATALOGUE: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case CatalogueActionTypes.LOAD_CATALOGUE_SUCCESS: {
      return {
        ... state,
        catalogue: action['catalogue'],
        loading: false
      };
    }
    case CatalogueActionTypes.LOAD_CATALOGUE_FAILURE: {
      return {
        ...state,
        error: action['error'],
        loading: false
      };
    }
    default:
      return state;
  }
}
