import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { LanguageModel } from 'ag-space-common';
import { SettingsService } from '../../services/settings.service';
import {
  GetAvailableLanguagesFailure,
  GetAvailableLanguagesSuccess,
  ReloadSelectedLanguage,
  SettingsActionTypes,
  SettingsUpdateRememberedLanguage,
  SetUserLanguage,
  SetUserLanguageFailure,
  SetUserLanguageSuccess,
  UpdateSiteLanguage
} from '../actions/settings.actions';
import { AuthTypes } from '../actions/auth.actions';
import { getCurrentLanguage, State } from '../reducers';

@Injectable()
export class SettingsEffects {

    @Effect() updateToBackendLang$ = this.actions$
        .pipe(
            ofType(AuthTypes.LOGIN_SUCCESS),
            withLatestFrom(this.store),
            switchMap(([action, store]) => {

                const lang = store.settings.availableLanguages.find(l => l.id === store.auth.user.language);
                const touched = store.settings.languageSelectTouched;

                if (touched) {
                    return EMPTY;
                } else {
                    return of(new SettingsUpdateRememberedLanguage(lang.key));
                }
            })
        );

    @Effect({ dispatch: false }) setCurrentlySelectedLanguage$ = this.actions$
        .pipe(
            ofType(SettingsActionTypes.GET_AVAILABLE_LANGUAGES_SUCCESS),
            withLatestFrom(this.store.select(getCurrentLanguage)),
            map(([_, currentLanguage]) => {
                if (currentLanguage) {
                    this.translate.use(currentLanguage.key);
                    this.store.dispatch(new ReloadSelectedLanguage());
                }
            })
        );

    @Effect({ dispatch: false }) changeLang$ = this.actions$
        .pipe(
            ofType(
              SettingsActionTypes.UPDATE_REMEMBERED_LANGUAGE,
              SettingsActionTypes.UPDATE_SITE_LANGUAGE,
            ),
            tap((action: SettingsUpdateRememberedLanguage | UpdateSiteLanguage) => {
                if (action.language) {
                    this.translate.use(action.language);
                }
            })
        );

    @Effect() getAvailableLanguages$ = this.actions$
        .pipe(
            ofType(SettingsActionTypes.GET_AVAILABLE_LANGUAGES),
            switchMap(() => this.settingsService.getAvailableLanguages()
                .pipe(
                    map(response => new GetAvailableLanguagesSuccess(response)),
                    catchError(response => of(new GetAvailableLanguagesFailure(response)))
                )
            )
        );

    @Effect({ dispatch: false }) availableLanguagesSuccess$ = this.actions$
        .pipe(
            ofType(SettingsActionTypes.GET_AVAILABLE_LANGUAGES_SUCCESS),
            tap((action: GetAvailableLanguagesSuccess) => {
                this.translate.addLangs(action.languages.map(lang => lang.key));
            })
        );

    @Effect() setUserLanguage$ = this.actions$
        .pipe(
            ofType(SettingsActionTypes.SET_USER_LANGUAGE),
            withLatestFrom(this.store),
            mergeMap(([action, state]: [SetUserLanguage, State]) => {
                return this.settingsService.setUserLanguage(state.auth.user.id, action.language)
                    .pipe(
                        map(response => new SetUserLanguageSuccess(action.language)),
                        catchError(response => of(new SetUserLanguageFailure(response)))
                    );
            })
        );

    @Effect() setUserLanguageSuccess$ = this.actions$
        .pipe(
            ofType(SettingsActionTypes.SET_USER_LANGUAGE_SUCCESS),
            map((action: SetUserLanguageSuccess) => action.language),
            map((language: LanguageModel) => new SettingsUpdateRememberedLanguage(language.key))
        );

    constructor(
        private store: Store<State>,
        private actions$: Actions,
        private translate: TranslateService,
        private settingsService: SettingsService
    ) { }

}
