import { Action } from '@ngrx/store';
import { FarmModel } from 'ag-space-common';

export enum FarmTypes {
  GET = '[Farm] Get',
  GET_SUCCESS = '[Farm] Get Success',
  GET_FAILURE = '[Farm] Get Failure',
  GET_CROPPING_TOTALS = '[CROPPING TOTALS] Get',
  GET_CROPPING_TOTALS_SUCCESS = '[CROPPING TOTALS] Get Success',
  GET_CROPPING_TOTALS_FAILURE = '[CROPPING TOTALS] Get Failure'
}

export class Get implements Action {
  readonly type = FarmTypes.GET;
  constructor(public id: number) { }
}

export class GetSuccess implements Action {
  readonly type = FarmTypes.GET_SUCCESS;
  constructor(public farm: FarmModel) { }
}

export class GetFailure implements Action {
  readonly type = FarmTypes.GET_FAILURE;
  constructor(public error: any) { }
}

export class GetCroppingTotals implements Action {
  readonly type = FarmTypes.GET_CROPPING_TOTALS;
  constructor(public id: number, public year: number, public season: number) { }
}

export class GetCroppingTotalsSuccess implements Action {
  readonly type = FarmTypes.GET_CROPPING_TOTALS_SUCCESS;
  constructor(public croppingTotals: FarmModel) { }
}

export class GetCroppingTotalsFailure implements Action {
  readonly type = FarmTypes.GET_CROPPING_TOTALS_FAILURE;
  constructor(public error: any) { }
}

export type All = Get | GetSuccess | GetFailure | GetCroppingTotals | GetCroppingTotalsSuccess | GetCroppingTotalsFailure;
