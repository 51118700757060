import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef, ComponentRef, Type
} from '@angular/core';

@Injectable()
export class ModalService {
  private childComponentRef: ComponentRef<any>;
  private modalElement: HTMLElement = null;
  private overlayElement: HTMLElement = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  public toggle<T>(component: Type<T>, childConfig?: ChildConfig, backDrop?: boolean, target?: HTMLElement ) {
    if (this.modalElement) {
      this.close();
    } else {
      this.open(component, childConfig, backDrop, target);
    }
  }

  public open<T>(component: Type<T>, childConfig?: ChildConfig, backDrop?: boolean, target?: HTMLElement) {
    if (this.modalElement) {
      return;
    }

    if (backDrop) {
      this.appendBackdrop(target ? true : false);
    }

    this.modalElement = document.createElement('div');
    this.modalElement.classList.add('modal-container');

    if (target) {
      this.setParentPosition(this.modalElement, target);
    }

    const childDomElem = this.createComponent(component, childConfig);
    this.modalElement.appendChild(childDomElem);

    document.body.appendChild(this.modalElement);
    this.addCloseOnBackEvent();
  }

  private setParentPosition(modalElement: HTMLElement, target: HTMLElement) {
    const childOffset = {
      top: target.offsetTop + target.clientHeight + 8,
      left: target.offsetLeft + target.clientWidth / 2
    };

    modalElement.setAttribute('style', `
      transform: translate(-50%, 0%);
      position: absolute;
      top: ${childOffset.top.toString()}px;
      left: ${childOffset.left.toString()}px;
    `);
  }

  private createComponent<T>(component: Type<T>, childConfig: ChildConfig): HTMLElement {
    this.childComponentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    this.attachConfig(childConfig, this.childComponentRef);

    this.appRef.attachView(this.childComponentRef.hostView);

    return (this.childComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
  }

  private addCloseOnBackEvent() {
    let that = this;
    window.addEventListener('popstate', function closeModal() {
      that.close();
      window.removeEventListener('popstate', closeModal, false);
    }, false);
  }

  private appendBackdrop(backDropTransparent) {
    this.overlayElement = document.createElement('div');
    this.overlayElement.classList.add('modal-overlay');
    if (backDropTransparent) {
      this.overlayElement.classList.add('backdrop--transparent');
    }
    this.overlayElement.addEventListener('click', () => this.close());
    document.body.appendChild(this.overlayElement);
  }

  public close() {
    if (this.modalElement) {
      this.appRef.detachView(this.childComponentRef.hostView);
      this.modalElement.parentNode.removeChild(this.modalElement);
      this.modalElement = null;

      if (this.overlayElement) {
        this.overlayElement.parentNode.removeChild(this.overlayElement);
        this.overlayElement = null;
      }
      this.childComponentRef.destroy();
    }
  }

  private attachConfig(config, componentRef) {
    let inputs = config.inputs;
    let outputs = config.outputs;
    for (let key in inputs) {
      if (key) {
        componentRef.instance[key] = inputs[key];
      }
    }
    for (let key in outputs) {
      if (key && outputs[key] instanceof Function) {
        componentRef.instance[key].subscribe(function () {
          outputs[key].apply(null, arguments);
        });
      }
    }
  }
}

interface ChildConfig {
  inputs: object;
  outputs: object;
}
