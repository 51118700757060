import { Action } from '@ngrx/store';
import { LocationModel } from '../../models/location.model';
import { SearchFilterModel } from '../../models/search-filter.model';

export enum AdvancedSearchTypes {
  SEARCH_OBJECT = '[Search] Search Object',
  RESET_SEARCH_OBJECT = '[Search] Reset Search Object',
  GET_AREAS = '[Areas] Get',
  GET_AREAS_SUCCESS = '[Areas] Get Success',
  GET_AREAS_FAILURE = '[Areas] Get Failure',
  RESET_AREAS = '[Areas] Reset'
}

export class UpdateSearchFilterObject implements Action {
  readonly type = AdvancedSearchTypes.SEARCH_OBJECT;
  constructor(public searchObj: SearchFilterModel) { }
}

export class ResetSearchFilterObject implements Action {
  readonly type = AdvancedSearchTypes.RESET_SEARCH_OBJECT;
  constructor() { }
}

export class GetArea implements Action {
  readonly type = AdvancedSearchTypes.GET_AREAS;
  constructor(public searchInput: string) { }
}

export class GetAreaSuccess implements Action {
  readonly type = AdvancedSearchTypes.GET_AREAS_SUCCESS;
  constructor(public areas: LocationModel[]) { }
}

export class GetAreaFailure implements Action {
  readonly type = AdvancedSearchTypes.GET_AREAS_FAILURE;
  constructor(public error: any) { }
}

export class ResetArea implements Action {
  readonly type = AdvancedSearchTypes.RESET_AREAS;
  constructor() { }
}

export type All = UpdateSearchFilterObject | ResetSearchFilterObject | GetArea | GetAreaSuccess | GetAreaFailure | ResetArea;
