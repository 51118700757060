import { Component, Output, EventEmitter, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { BaseModel } from 'ag-space-common';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { OrderByPipe } from '../../pipes/order-by.pipe';
import { map } from 'rxjs/operators';

@Component({
    selector: 'type-ahead',
    templateUrl: './type-ahead.component.html',
    styleUrls: ['./type-ahead.component.scss']
})
export class TypeAheadComponent implements OnInit, OnDestroy {
  @ViewChild('typeAheadElem') input: ElementRef;
  @Input() list: Array<any> = [];
  @Input() selectedItem: any;
  @Input() errorMsg: string;
  @Input() showError: boolean;
  @Input() makeRequest: boolean;
  @Input() listObservable: Observable<any>;
  @Input() debounce: number;
  @Input() loading: number;
  @Input() orderingProp: string;
  @Input() firstItemId: number;
  @Output() selectItem = new EventEmitter<BaseModel>();
  @Output() error  = new EventEmitter<boolean>();
  @Output() searchValue  = new EventEmitter<string>();
  filteredItems: Array<any> = [];
  selected: any;
  itemName: '';
  itemModified: boolean;
  listSub: Subscription;
  inputSub: Subscription;

  constructor(private orderByPipe: OrderByPipe) {}

  ngOnInit() {
    this.filteredItems = {...this.list};
    if (this.selectedItem && this.selectedItem.displayName) {
      this.itemName = this.selectedItem.name;
    }
    if (typeof this.selectedItem === 'number') {
      this.itemName = this.list.filter(item => item.id === this.selectedItem)[0].displayName;
    }

    let input$ = fromEvent(this.input.nativeElement, 'keyup')
      .debounceTime(this.debounce);

    this.inputSub = input$.subscribe(() => {
      this.searchValue.emit(this.itemName.trim());
    });
  }

  filterItemsByViewValue(value: number) {
    return this.list.filter(item =>
      item.displayName.toLowerCase().indexOf(value.toString().toLowerCase()) > -1);
  }


  onFocus() {
    if (this.input.nativeElement.value === '') {
      this.itemName = '';
    }
    this.filteredItems = this.itemName ? this.filterItemsByViewValue(this.itemName) : this.list.slice();
    if (this.orderingProp) {
      this.filteredItems = this.orderByPipe.transform(this.filteredItems, this.orderingProp);
    }
    if (this.firstItemId) {
      this.filteredItems = this.getListWithItemInTop(this.filteredItems, this.firstItemId);
    }
  }

  selectItemFromList(option) {
    this.itemName = option.name;
    this.selected = option;
    this.showError = false;
    this.error.emit(false);
    this.selectItem.emit(this.selected);
    this.itemModified = false;
  }

  onBlur() {
    if (this.itemModified) {
      this.selected = null;
      this.error.emit(!!this.itemName);
      this.selectItem.emit(null);
    }

    setTimeout(() => {
      this.filteredItems = [];
    }, 299);
  }

  updateList() {
    if (this.makeRequest) {
      return;
    }
    this.itemModified = true;
    if (!this.selected && !this.itemName) {
      this.selectItem.emit(null);
      this.error.emit(false);
    }
    this.filteredItems = this.itemName ? this.filterItemsByViewValue(this.itemName) : this.list.slice();
    if (this.orderingProp) {
      this.filteredItems = this.orderByPipe.transform(this.filteredItems, this.orderingProp);
    }
    if (this.firstItemId) {
      this.filteredItems = this.getListWithItemInTop(this.filteredItems, this.firstItemId);
    }
    if (!this.filteredItems.length || (!this.selected && this.itemName)) {
      this.showError = true;
      this.error.emit(true);
    }
  }

  getListWithItemInTop(list, itemInTopId) {
    let newList = [...list];
    let itemIndex = newList.findIndex(item => item.id === itemInTopId);
    let firstItem = newList.splice(itemIndex, 1)[0];
    newList.unshift(firstItem);
    return newList;
  }

  getSearchResult() {
    if (!this.makeRequest) {
      return;
    }
    this.listSub = this.listObservable.pipe(map(function (data) {
      // it is used to add displayName property, which will be needed for the dropdown display
      data.map(function (entry) {
        entry.displayName = entry.name;
        return entry;
      });
      return data;
    })).subscribe(data => {
      this.itemModified = true;
      this.filteredItems = data;
      if (this.orderingProp) {
        this.filteredItems = this.orderByPipe.transform(this.filteredItems, this.orderingProp);
      }
      if (this.listSub) {
        this.listSub.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    if (this.listSub) {
      this.listSub.unsubscribe();
    }
    this.inputSub.unsubscribe();
  }

}
