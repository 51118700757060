export const sort = (a, b, asc = true): number => {
    if (typeof a === 'string' || a instanceof String ) {
        a = a.toUpperCase();
    }
    if (typeof b === 'string' || b instanceof String ) {
        b = b.toUpperCase();
    }

    let result = 0;
    if (a > b) {
        result = 1;
    } else if (a < b) {
        result = -1;
    }

    if (asc) {
        return result;
    } else {
        return result * -1;
    }
};
