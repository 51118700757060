import * as FarmsActions from '../actions/farms.actions';
import { FarmModel } from 'ag-space-common';
import { sort } from '../../../shared/sort';
import { FarmsTypes } from '../actions/farms.actions';

export type Action = FarmsActions.All;

export interface FarmsState {
    farms: FarmModel[];
    farmsSorted: FarmModel[];
    sortColumn: string;
    sortAsc: boolean;
    loading: boolean;
    error: string;
    farmsLoading: boolean;
    farmsById: {};
}

const defaultState: FarmsState = {
    farms: [],
    farmsSorted: [],
    sortColumn: 'farm',
    sortAsc: true,
    loading: false,
    error: null,
    farmsLoading: false,
    farmsById: {}
};

const sortFarms = (farms: FarmModel[], column: string, ascending: boolean): FarmModel[] => {
    return farms.slice(0).sort((a: any, b: any) => {
        switch (column) {
            case 'farm':
                return sort(a.name, b.name, ascending);
            case 'farmer':
                return sort(a.farmer.name, b.farmer.name, ascending);
            case 'address':
                return sort(a.address, b.address, ascending);
            case 'area':
                return 0;
            // return sort(a.area, b.area, ascending);
            case 'coordinates':
                return 0;
        }
    });
};

export function farmsReducer (state: FarmsState = defaultState, action: Action): FarmsState {
    switch (action.type) {

        case FarmsTypes.GET: {
            return Object.assign({}, state, {
                farms: [],
                farmsSorted: [],
                loading: true,
                error: null
            });
        }

        case FarmsTypes.GET_SUCCESS: {
            let farms = action.farms;
            return Object.assign({}, state, {
                farms,
                farmsSorted: sortFarms(farms, state.sortColumn, state.sortAsc),
                loading: false,
                error: null
            });
        }

        case FarmsTypes.GET_FAILURE: {
            return Object.assign({}, state, {
                farms: [],
                loading: false,
                error: action.error
            });
        }

        case FarmsTypes.SORT: {
            return Object.assign({}, state, {
                farmsSorted: sortFarms(state.farms, action.column, action.ascending),
                sortColumn: action.column,
                sortAsc: action.ascending
            });
        }

      case FarmsTypes.SEARCH_FARMS:
      case FarmsTypes.GET_FARMS_BY_ID: {
        return {
          ...state,
          farmsLoading: true,
          error: null
        };
      }

      case FarmsTypes.GET_FARMS_BY_ID_SUCCESS: {
        return {
          ... state,
          farmsLoading: false,
          farmsById: {
            ...state.farmsById,
            [action.farmerId]: action.farms
          }
        };
      }

      case FarmsTypes.GET_FARMS_BY_ID_FAILURE: {
        return {
          ...state,
          error: action.error,
          farmsLoading: false,
          farmsById: {
            ...state.farmsById,
            [action.farmerId]: []
          }
        };
      }

        default: {
            return state;
        }
    }
};
