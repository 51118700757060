import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts-more-node';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { throttleTime } from 'rxjs/operators';

@Component({
  selector: 'crop-health-empty',
  template: `<div #chartEmptyTarget></div>`
})
export class CropHealthEmptyComponent implements OnInit, OnDestroy {
  chart: Highcharts.ChartObject;
  @ViewChild('chartEmptyTarget') chartTarget: ElementRef;
  @Input() chartName: string;
  resize$: Observable<any>;
  resizeSubscription: Subscription;
  responsiveOptions = {
    rules: [{
      condition: {
        maxWidth: 400
      },
      chartOptions: {
        chart: {
          height: 300
        }
      }
    },
      {
        condition: {
          minWidth: 401
        },
        chartOptions: {
          chart: {
            height: 350
          }
        }
      }]
  };

  constructor(private translate: TranslateService) {
    this.resize$ = fromEvent(window, 'resize').pipe(throttleTime(300));
  }

  ngOnInit() {
    this.getDefaultChart();
    this.resizeSubscription = this.resize$.subscribe(() => {
      this.getDefaultChart();
    });

  }

  checkWidth(innerWidth) {
    if (innerWidth > 1024) {
      return innerWidth / 3;
    }
    if (innerWidth < 1024 && innerWidth > 600) {
      return innerWidth / 2;
    }
    if (innerWidth < 600) {
      return innerWidth * 0.8;
    }
  }

  getDefaultChart() {
    this.chart = Highcharts.chart(this.chartTarget.nativeElement, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        width: this.checkWidth(window.innerWidth),
        backgroundColor: null
      },
      title: {
        text: this.chartName
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            connectorColor: '#595959'
          }
        }
      },
      series: [{
        data: [{
          name: this.translate.instant('CHARTS.MISSING_CROP_DATA'),
          color: '#ffffff',
          borderColor: '#595959',
          border: 1,
          y: 100,
        }]
      }],
      responsive: this.responsiveOptions
    });
  }

  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }

}
