import { Action } from '@ngrx/store';

import { FarmModel } from 'ag-space-common';
import { SearchFilterModel } from '../../models/search-filter.model';

export enum FarmsTypes {
  GET = '[Farms] Get',
  GET_SUCCESS = '[Farms] Get Success',
  GET_FAILURE = '[Farms] Get Failure',
  SORT = '[Farms] Sort',
  GET_FARMS_BY_ID = '[FARMS_BY_ID] Get',
  GET_FARMS_BY_ID_SUCCESS = '[FARMS_BY_ID] Get Success',
  GET_FARMS_BY_ID_FAILURE = '[FARMS_BY_ID] Get Failure',
  SEARCH_FARMS = '[Search] Search farms'
}

export class Get implements Action {
    readonly type = FarmsTypes.GET;
}

export class GetSuccess implements Action {
    readonly type = FarmsTypes.GET_SUCCESS;
    constructor(public farms: FarmModel[]) { }
}

export class GetFailure implements Action {
    readonly type = FarmsTypes.GET_FAILURE;
    constructor(public error: any) { }
}

export class Sort implements Action {
    readonly type = FarmsTypes.SORT;
    constructor(public column: string, public ascending: boolean) { }
}

export class GetFarmsById implements Action {
  readonly type = FarmsTypes.GET_FARMS_BY_ID;
  constructor(public farmerId: any, public year: number, public season: number) { }
}

export class GetFarmsByIdSuccess implements Action {
  readonly type = FarmsTypes.GET_FARMS_BY_ID_SUCCESS;
  constructor(public farms: any, public farmerId: number) { }
}

export class GetFarmsByIdFailure implements Action {
  readonly type = FarmsTypes.GET_FARMS_BY_ID_FAILURE;
  constructor(public error: any, public farmerId: number) { }
}

export class SearchFarms implements Action {
  readonly type = FarmsTypes.SEARCH_FARMS;
  constructor(public searchObject: SearchFilterModel, public growerId: number) { }
}

export type All = Get | GetSuccess | GetFailure | Sort | GetFarmsById | GetFarmsByIdSuccess | GetFarmsByIdFailure | SearchFarms;
