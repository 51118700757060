import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';

import { catchError, mergeMap, map, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
  AuthTypes,
  Login,
  LoginSuccess,
  LoginFailure,
  GetDistributorsSuccess,
  GetDistributorsFailure,
  GetDistributors,
  GetCurrentUser,
  GetCurrentUserSuccess,
  GetCurrentUserFailure,
  Logout
} from '../actions/auth.actions';
import { GetSeasons, SetSeasonDefault } from '../actions/season.actions';
import { DistributorService } from '../../providers/distributor.service';
import { AuthService } from 'auth-lib';
import { ApiService } from 'ag-space-common';

@Injectable()
export class AuthEffects {

  @Effect() login$ = this.actions$
    .pipe(ofType(AuthTypes.LOGIN),
      map(action => (action as Login).credentials),
      switchMap((action) =>
        this.authService.login(action).pipe(
          map(() => new GetCurrentUser()),
          catchError(err => of(new LoginFailure(err))))
      ));

  @Effect() userData$ = this.actions$
    .pipe(
      ofType(AuthTypes.GET_CURRENT_USER),
      switchMap(() => {
        return this.apiService.getUserDetails().pipe(
          switchMap((user: any) => {
            return [
              new GetCurrentUserSuccess(user),
              new LoginSuccess(),
              new SetSeasonDefault(),
              new GetSeasons(user.distributor),
              new GetDistributors(user.id)
            ];
          }),
          catchError(err => of(new GetCurrentUserFailure(err)))
        );
      })
    );

  @Effect({ dispatch: false }) redirect$ = this.actions$
    .pipe(ofType(AuthTypes.LOGIN_SUCCESS),
      map(() => {
        this.router.navigate(['/']);
      }));

  @Effect({ dispatch: false }) logout$ = this.actions$
    .pipe(
      ofType(AuthTypes.LOGOUT),
      switchMap((action: Logout) => {
        let logout$ = this.authService.logout();

        if (action.isTokenExpired) {
          logout$ = this.authService.clearAuthData();
        }

        return logout$.pipe(
          tap(() => {
            this.router.navigate(['/login']).finally(() => {
              if (action.isTokenExpired) {
                this.toast.error(this.translate.instant('AUTH.LOGOUT_MESSAGE'));
              }
            });
          })
        );
      })
    );

  @Effect() getDistributors$ = this.actions$
    .pipe(ofType(AuthTypes.GET_DISTRIBUTORS),
      switchMap((action: GetDistributors) => this.distributorService.getFarmerDistributors(action.farmerId).pipe(
        map(distributors => new GetDistributorsSuccess(distributors)),
        catchError(err => of(new GetDistributorsFailure(err))))
      ));

  constructor(
    private translate: TranslateService,
    private toast: ToastrService,
    private actions$: Actions,
    private router: Router,
    private distributorService: DistributorService,
    private authService: AuthService,
    private apiService: ApiService
  ) {
  }

}
