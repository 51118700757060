import {Injectable} from '@angular/core';
import {LanguageModel} from 'ag-space-common';
import {map} from 'rxjs/operators/map';
import {_throw} from 'rxjs/observable/throw';
import {HttpClient} from '@angular/common/http';


// Map of configured *front-end* languages, Any new languages should be added here.
// Object name = Backend name of language
// label = User facing name of language
// key = name of i18n json file.
// tag = the BCP47 compliant language tag. (ISO - 639-1);
const languageMap = {
  English: {
    label: 'English',
    key: 'english',
    imgUrl: 'assets/images/flags/United-Kingdom.png',
    tag: 'en'
  },
  Polish: {
    label: 'Polski',
    key: 'polish',
    imgUrl: 'assets/images/flags/Poland.png',
    tag: 'pl'
  },
  Romanian: {
    label: 'Romanian',
    key: 'romanian',
    imgUrl: 'assets/images/flags/Romania.png',
    tag: 'ro'
  },
  Ukrainian: {
    label: 'Ukrainian',
    key: 'ukrainian',
    imgUrl: 'assets/images/flags/Ukraine.png',
    tag: 'uk'
  },
  Turkish: {
    label: 'Turkish',
    key: 'turkish',
    imgUrl: 'assets/images/flags/Turkey.png',
    tag: 'tr'
  }
};

@Injectable()
export class SettingsService {
  private urlApiBase = `{k8sUrl}/api/root/v2.0`;

  constructor(
    private http: HttpClient
  ) {
  }

  setUserLanguage(userId: number, language: LanguageModel) {
    if ((!userId || typeof userId !== 'number') || !language) {
      _throw('User ID / language is invalid');
    }

    return this.http.patch(
      `${this.urlApiBase}/users/${userId}`,
      {language: language.id}
    );
  }

  getAvailableLanguages = () => {
    return this.http.get(`${this.urlApiBase}/languages`)
      .pipe(
        map((response: any[]) => response
          .filter(lang => languageMap[lang.name])
          .map(lang => Object.assign({}, lang, languageMap[lang.name]))
        ))
    }
}
