
import {take, map, filter, combineLatest} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as json2csv from 'json2csv';
import * as FileSaver from 'file-saver';

import { State } from '../core/store/reducers';
import { CropTotalsModel } from '../core/models/crop-totals.model';
import { Get, Sort } from '../core/store/actions/crops.actions';
import { TranslateService } from '@ngx-translate/core';
import { DistributorUpdate } from '../core/store/actions/auth.actions';
import { GetSeasons } from '../core/store/actions/season.actions';

@Component({
  templateUrl: './crops.component.html'
})
export class CropsComponent implements OnInit, OnDestroy {
  selected = {};
  loading$: Observable<boolean>;
  searchText = '';
  cropsFiltered$: Observable<CropTotalsModel[]>;
  seasonSubscription: Subscription;
  sortSubscription: Subscription;
  sortColumn: string;
  sortAsc: boolean;
  year: number;
  season: number;
  headers: Array<any>;
  distributorSub: Subscription;
  distributorId: any;
  cropsSub: Subscription;
  cropsList: CropTotalsModel[];

  constructor(
    private store: Store<State>,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.getHeaderLabels();
    this.loading$ = this.store.select(state => state.crops.loading);

    this.cropsFiltered$ = this.store.select(state => state.crops.cropsSorted).pipe(combineLatest(
      this.store.select(state => state.search.farmId),
      (crops, farmId) => {
        if (farmId === null) {
          return crops;
        }
        let filteredCrops = [];
        for (let crop of crops) {
          let farms = crop.farms.filter(farm => farm.id === farmId);
          if (farms.length !== 0) {
            filteredCrops.push(Object.assign({}, crop, {
              farms
            }));
          }
        }
        return filteredCrops;
      }
    ));

    this.cropsSub = this.cropsFiltered$.subscribe(crops => {
      this.cropsList = crops.map(crop => {
        crop.harvest = this.calculateHarvest(crop.target, crop.area);
        let cropHarvestTotal = 0;
        crop.farms.forEach(farm => {
          farm.harvest = this.calculateHarvest(farm.target, farm.area);
          if (!farm.predictedYield) return;
          farm.predictedHarvest = this.calculateHarvest(farm.predictedYield, farm.area);
          cropHarvestTotal = cropHarvestTotal + farm.predictedHarvest;
        });
        crop.predictedHarvest = cropHarvestTotal > 0 ? cropHarvestTotal : null;
        return crop;
      });
    });

    this.seasonSubscription = this.store.select(state => state.season).subscribe((season) => {
      this.updateSeason(season);
    });
    this.sortSubscription = this.store.select(state => state.crops.sortColumn).pipe(combineLatest(
      this.store.select(state => state.crops.sortAsc),
      (sortColumn, sortAsc) => {
        return { sortColumn, sortAsc };
      }
    )).subscribe((sort) => {
      this.sortColumn = sort.sortColumn;
      this.sortAsc = sort.sortAsc;
    });
    this.distributorSub = this.store.select(state => state.auth.distributor).subscribe(distributorId => {
      this.distributorId = distributorId;
      this.store.dispatch(new Get(this.distributorId, this.year, this.season));
    });
  }

  calculateHarvest(harvest: number, area: number): number {
   return area * harvest;
  }

  getHeaderLabels() {
    this.translate.get('LABELS').subscribe(labels => {
      this.headers = [
        { name: this.translate.instant(labels.CROP), field: 'name' },
        { name: this.translate.instant(labels.HECTARES), field: 'area', tooltip: true },
        { name: this.translate.instant(labels.TOTAL_HARVEST), field: 'harvest', tooltip: true },
        { name: this.translate.instant(labels.TARGET_YIELD_THA), field: 'yield', tooltip: true },
        { name: this.translate.instant(labels.PREDICTED_HARVEST), field: 'predictedHarvest', tooltip: true },
        { name: this.translate.instant(labels.PREDICTED_YIELD), field: 'predictedYield', tooltip: true }
      ];
    });
  }

  ngOnDestroy() {
    this.seasonSubscription.unsubscribe();
    this.sortSubscription.unsubscribe();
    this.distributorSub.unsubscribe();
    this.cropsSub.unsubscribe();
  }

  sort(column: string) {
    let ascending = this.sortAsc;
    if (this.sortColumn === column) {
      ascending = !ascending;
    } else {
      ascending = true;
    }
    this.store.dispatch(new Sort(column, ascending));
  }

  updateSeason(values) {
    this.selected = {};
    this.year = values.year;
    this.season = values.season;
    this.store.dispatch(new Get(this.distributorId, this.year, this.season));
  }

  select(index: number) {
    if (this.selected[index]) {
      this.selected[index] = false;
    } else {
      this.selected[index] = true;
    }
  }

  export() {
    this.store.select(state => state.crops).pipe(
      filter(state => !state.loading),
      map(state => state.crops),
      take(1),)
      .subscribe((crops) => {
        let flatCrops = [];
        for (let crop of crops) {
          for (let farm of crop.farms) {
            flatCrops.push({
              crop_name: crop.crop.name,
              farm_name: farm.name,
              hectares: farm.area.toFixed(3),
              target_harvest: farm.harvest.toFixed(2),
              target_yield: farm.target.toFixed(1),
              predicted_harvest: farm.predictedHarvest ? farm.predictedHarvest.toFixed(2) : null,
              predicted_yield: farm.predictedYield ? farm.predictedYield.toFixed(1) : null
            });
          }
        }
        let csv = json2csv({
          data: flatCrops
        });

        let data = new Blob([csv], { type: 'text/csv' });
        FileSaver.saveAs(data, 'crops-' + this.year + '-' + this.season + '.csv');
      });
  }

  setDistributor(distributor) {
    this.store.dispatch(new DistributorUpdate(distributor));
    this.store.dispatch(new GetSeasons(distributor));
  }

}
