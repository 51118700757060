import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { AgmInfoWindow } from '@agm/core';
import { FieldModel } from 'ag-space-common';

@Component({
    selector: 'maps-field-cgi-info',
    templateUrl: './maps-field-cgi-info.component.html',
    styleUrls: ['./maps-field-cgi-info.component.scss']
})
export class MapsFieldCGIInfoComponent implements OnChanges {

    @Input() field: FieldModel | any;
    @Input() cgi: any;
    @ViewChild(AgmInfoWindow) infoWindow: AgmInfoWindow;
    isOpen = false;

    open() {
        this.infoWindow.open();
        this.isOpen = true;
    }

    close() {
        if (this.infoWindow && this.isOpen) {
            this.infoWindow.close();
        }
        this.isOpen = false;
    }

    ngOnChanges() {
        if (!this.cgi && this.isOpen) {
            this.close();
        }
    }

}
