
import {combineLatest,  takeUntil ,  filter, withLatestFrom } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ImageryType, ObservationModel, ImageryTypes, FarmModel, AgsMapLayerCGIComponent, ImageryGroup } from 'ag-space-common';
import { Observable ,  Subject ,  Subscription } from 'rxjs';
import { State } from '../../../core/store/reducers';
import { MapsObservationInfoComponent } from '../maps-observation-info/maps-observation-info.component';
import { FieldComponent } from '../../../field/field.component';
import { ModalService } from '../../../core/providers/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdateMotionSyncParams } from '../../../core/store/actions/map.actions';
import { MapMotionSyncState } from '../../../core/store/reducers/map.reducer';
import uniqBy from 'lodash-es/uniqBy';
import { CGILoad } from '../../../core/store/actions/cgi.actions';
import { MapsSelectComponent } from '../maps-select/maps-select.component';
import { MapsFieldCGIInfoComponent } from '../maps-field-cgi-info/maps-field-cgi-info.component';

@Component({
  selector: 'maps-map',
  templateUrl: './maps-map.component.html'
})
export class MapsMapComponent implements OnInit, OnDestroy {

  @Input() master = false;
  @Output() mapType: EventEmitter<ImageryType> = new EventEmitter();
  @Input() observations: any;
  @Input() showCroppedFields = false;
  @Input() farmId: number;
  @Input() zones = [];

  @ViewChild(MapsObservationInfoComponent) observationInfoWindow: MapsObservationInfoComponent;
  @ViewChild(MapsSelectComponent) mapSelect: MapsSelectComponent;
  @ViewChild(MapsFieldCGIInfoComponent) cgiInfoWindow: MapsFieldCGIInfoComponent;
  @ViewChild(AgsMapLayerCGIComponent) cgiComponent: AgsMapLayerCGIComponent;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  views$: Observable<any>;
  farm$: Observable<FarmModel>;
  showLabels$: Observable<boolean>;
  selectedObservation: ObservationModel = null;
  crops$: Observable<any>;
  year$: Observable<any>;
  season$: Observable<any>;
  motionSyncParams$: Observable<MapMotionSyncState>;
  motionSyncStatus$: Observable<boolean>;
  selectedField: any = null;
  motionSyncEnabled = false;
  zoom = 2;
  longitude = null;
  latitude = null;
  public imageryTypes = ImageryTypes;
  selectedImageryType: any;
  sarCrops$: Observable<any>;
  cgiInfo: any = null;
  hideLegendNumbersFor$: Observable<any>;
  soilAnalysisError = false;
  observationsSub: Subscription;
  BYDVRisk: any;
  currentZoneBYDVRisk = null;
  cropping = [];

  constructor(private store: Store<State>, private modalService: ModalService, private translate: TranslateService) {}

  ngOnInit() {
    this.store.dispatch(new CGILoad());
    this.motionSyncStatus$ = this.store.select(state => state.map.motionSync.enabled);
    this.motionSyncParams$ = this.store.select(state => state.map.motionSync);
    this.views$ = this.store.select(state => state.map.views);
    this.motionSyncStatus$.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(status => {
      this.motionSyncEnabled = status;
      if (status) {
        this.sendMapChanges({ latitude: this.latitude, longitude: this.longitude, zoom: this.zoom });
      }
    });
    this.crops$ = this.store.select(state => state.crops.cropsByFarm);
    this.crops$.subscribe( (val) => {
      this.cropping.push({
        crop: val.assignedCrop,
        id: val.id,
        plantingDate: val.plantingDate
      });
    });
    this.farm$ = this.store.select(state => state.farm.farm);
    this.showLabels$ = this.store.select(state => state.map.showLabels);
    this.year$ = this.store.select(state => state.season.year);
    this.season$ = this.store.select(state => state.season.season);
    this.hideLegendNumbersFor$ = this.store.select(state => state.map.hideLegendNumbersFor);
    this.sarCrops$ =  this.store.select(state => state.crops.cropsByFarm).pipe(withLatestFrom(
      this.store.select(state => state.cgi.cropModelIndex),
      (croppings, cgiIndex) => {
        const cropIds = Object.keys(cgiIndex).map(k => +k);

        const filtered = Object.values(croppings).filter(cropping => {
          return cropping.plantingDate && cropIds.indexOf(cropping.cropId) !== -1;
        });

        return uniqBy(filtered, 'cropId').map((cropping: any) => ({ name: cropping.assignedCrop.name, displayName: cropping.assignedCrop.displayName, value: cropping.cropId }));
      }));
    this.observationsSub = this.store.select(state => state.observations.observations).pipe(combineLatest(
      this.store.select(state => state.season),
      (observations, season) => ({observations, season})))
      .subscribe(() => {
          if (this.observationInfoWindow.isOpen) {
            this.observationInfoWindow.close();
          }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.observationsSub.unsubscribe();
  }

  handleMapChange($event) {
    if ((this.selectedImageryType && this.selectedImageryType.option) !== $event.option ||
      (this.selectedImageryType && this.selectedImageryType.type !== $event.type )) {
      this.cgiInfoWindow.close();
    }

    if ($event.type) {
      this.mapType.emit($event);
    }
    this.selectedImageryType = {
      type: $event.type,
      group: $event.group,
      option: $event.option
    };
  }

  markerClicked(marker) {
    this.selectedObservation = marker;
    this.observationInfoWindow.open();
  }

  handleCgiError() {
    this.mapSelect.loadError = true;
  }

  selectZone(zone) {
    this.selectedField = zone.field;
    let filteredZone = this.zones.filter(zoneItem => {
      return zoneItem.id === zone.zone.id
    }).map(item => item)[0];
    if (this.selectedImageryType.type && this.selectedImageryType.type.type === ImageryTypes.CGI) {
      return this.openCGI(zone);
    } else {
      let selectedZone = {...zone, farmId: this.farmId, farm$: this.farm$};
      selectedZone.zone.crop = filteredZone.crop || {name: this.translate.instant('SHARED.UNALLOCATED')};
      this.modalService.open(FieldComponent, {
        inputs: {
          data: selectedZone
        },
        outputs: {}
      }, true);
    }
  }

  openCGI(zone) {
    const fieldId = zone.field.id;
    this.cgiInfo = this.cgiComponent.data.find(data => +data.id === +fieldId);

    if (typeof this.cgiInfo !== 'undefined') {
      this.cgiInfoWindow.open();
    }
  }

  onZoomChange($event: number) {
    if (this.master) {
      this.zoom = $event;
      this.sendMapChanges({ zoom: $event });
    }
  }

  onCenterChange($event: { lat: number, lng: number }) {
    if (this.master) {
      this.sendMapChanges({ latitude: $event.lat, longitude: $event.lng, zoom: this.zoom });
    }
  }

  sendMapChanges(changes) {
    if (this.master && this.motionSyncEnabled) {
      this.store.dispatch(new UpdateMotionSyncParams(changes));
    }
  }

  ready() {
    this.motionSyncParams$.pipe(
      takeUntil(this.ngUnsubscribe),
      filter(ms => ms.enabled && !this.master)
    ).subscribe(ms => {
      this.zoom = ms.zoom;
      this.latitude = ms.latitude;
      this.longitude = ms.longitude;
    });
  }

  handleHideLegendLabels(group: ImageryGroup, hideGroupFromStore) {
    return group && group.key && hideGroupFromStore && hideGroupFromStore.group === group.key;
  }

  checkSoilAnalysisIsPresent(ev) {
    this.soilAnalysisError = ev;
  }

  checkBYDVRisk(zoneRiskMap: { zoneId: number, riskStatus: string }[]) {
    this.BYDVRisk = zoneRiskMap;
    if (this.selectedImageryType.group && this.selectedImageryType.group.key === 'BYDV' && this.selectedField) {
      const bydvRisk = zoneRiskMap.find(zoneRisk => zoneRisk.zoneId === this.selectedField.id);
      if (bydvRisk) {
        return this.currentZoneBYDVRisk = bydvRisk.riskStatus;
      }
    }
    return this.currentZoneBYDVRisk = null;
  }
}
