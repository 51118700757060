
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { GetObservations, GetObservationsSuccess, GetObservationsFailure, ObservationsTypes } from '../actions/observations.actions';
import { AGSObservationService } from 'ag-space-common';
const observationIconMap = {
  'Pest': 'assets/images/pest-pin.png',
  'Weed': 'assets/images/weed-pin.png',
  'Disease': 'assets/images/disease-pin.png',
  'Other': 'assets/images/other-pin.png'
};

@Injectable()
export class ObservationsEffects {

  @Effect() getChartsFarmers$ = this.actions$
    .pipe(ofType(ObservationsTypes.GET),
      switchMap((action: GetObservations) => this.api.getObservationsForFarm(action.id).pipe(
      mergeMap(observations => {
        const observationsWithImageUrl = observations.map(item => ({
            ...item,
            markerIcon: observationIconMap[item.observationType.observationCategory.name]
          }));
        return observableOf(new GetObservationsSuccess(observationsWithImageUrl, action.id));
      }),
      catchError(err => observableOf(new GetObservationsFailure(err))), )
    ));

  constructor(
    private actions$: Actions,
    private api: AGSObservationService
  ) {
  }

}
