import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MapsSelectComponent } from './components/maps-select/maps-select.component';
import { MyDatePickerModule } from 'mydatepicker';
import { MapsObservationInfoComponent } from './components/maps-observation-info/maps-observation-info.component';
import { AgmCoreModule } from '@agm/core';
import { ModalService } from '../core/providers/modal.service';
import { OrderByPipe } from './pipes/order-by.pipe';
import { FarmFarmerPipe } from './pipes/farm-farmer.pipe';
import { SafeNumberPipe } from './pipes/safe-number.pipe';
import { CropFarmFarmerPipe } from './pipes/crop-farm-farmer.pipe';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { AgSpaceCommonModule } from 'ag-space-common';
import { FieldSoilAnalysisComponent } from './components/field-soil-analysis/field-soil-analysis.component';
import { FieldComponent } from '../field/field.component';
import { CropHealthComponent } from './components/crop-health/crop-health.component';
import { GrowerNamePipe } from './pipes/grower-name.pipe';
import { MapsMapComponent } from './components/maps-map/maps-map.component';
import { MapsFieldCGIInfoComponent } from './components/maps-field-cgi-info/maps-field-cgi-info.component';
import { CropHealthEmptyComponent } from './components/crop-health-empty/crop-health-empty.component';
import { DistributorSelectComponent } from './components/distributor-select/distributor-select.component';
import { ImageViewComponent } from './components/image-modal/image-view.component';
import { TypeAheadComponent } from './components/type-ahead/type-ahead.component';
import { AdvancedSearchComponent } from './components/advanced-search/advanced-search.component';
import { CropGrowthPredictionComponent } from './components/crop-growth-prediction/crop-growth-prediction.component';
import { CropHealthBarComponent } from './components/crop-health-bar/crop-health-bar.component';


@NgModule({
  declarations: [
    MapsSelectComponent,
    MapsObservationInfoComponent,
    OrderByPipe,
    FarmFarmerPipe,
    CropFarmFarmerPipe,
    SafeNumberPipe,
    FieldSoilAnalysisComponent,
    FieldComponent,
    CropHealthComponent,
    GrowerNamePipe,
    MapsMapComponent,
    MapsFieldCGIInfoComponent,
    CropHealthEmptyComponent,
    DistributorSelectComponent,
    ImageViewComponent,
    TypeAheadComponent,
    AdvancedSearchComponent,
    CropGrowthPredictionComponent,
    CropHealthBarComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MyDatePickerModule,
    AgmCoreModule,
    AgSpaceCommonModule
  ],
  exports: [
    MapsSelectComponent,
    MapsObservationInfoComponent,
    OrderByPipe,
    FarmFarmerPipe,
    CropFarmFarmerPipe,
    SafeNumberPipe,
    FieldSoilAnalysisComponent,
    FieldComponent,
    CropHealthComponent,
    GrowerNamePipe,
    MapsMapComponent,
    MapsFieldCGIInfoComponent,
    CropHealthEmptyComponent,
    DistributorSelectComponent,
    ImageViewComponent,
    TypeAheadComponent,
    AdvancedSearchComponent,
    CropGrowthPredictionComponent,
    CropHealthBarComponent
  ],
  entryComponents: [
    FieldComponent,
    ImageViewComponent,
    AdvancedSearchComponent,
    CropGrowthPredictionComponent
  ],
  providers: [
    OrderByPipe,
    FarmFarmerPipe,
    CropFarmFarmerPipe,
    ModalService,
    GoogleMapsAPIWrapper,
    GrowerNamePipe,
    DecimalPipe,
    AdvancedSearchComponent,
    CropGrowthPredictionComponent
  ]
})
export class SharedModule { }
