import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { AnalysisService } from 'ag-space-common';
import { CropNameService } from '../../../core/providers/crop-name.service'

const getDefaultTotals = () => ({
  farmerCrops: [],
  latestPrediction: 0,
  twentyDayAverage: 0,
  eightFifteenDayAverage: 0,
  twentyTwentyDayAverage: 0,
  sevenDayAverage: 0,
  fifteenFifteenAverage: 0,
  trend: null,
  totalFieldsArea: 0,
  totalRate: 0
});

@Component({
  selector: 'crop-growth-prediction',
  templateUrl: './crop-growth-prediction.component.html'
})
export class CropGrowthPredictionComponent implements OnChanges, OnDestroy {
  @Input() public farmId: number;
  @Input() public cropId: number;
  @Input() public year: number;
  @Input() public season: number;
  @Input() public croppings: any[];

  open = false;
  trend = false;
  loading = false;
  predictions = [];
  selectedCrop: string;
  predictionFieldsCount = 0;
  croppingFieldsCount = 0;
  predictionsByField = [];
  computedPredictions = null;

  constructor(
    private analysisService: AnalysisService,
    private cropName: CropNameService
  ) {}

  ngOnChanges() {
    this.compute();
  }

  compute() {
    if (!this.croppings || !this.croppings.length) {
      return;
    }
    this.loading = true;
    let drools = null;
    this.analysisService.getCropGrowthPrediction(this.farmId, this.cropId, this.year, this.season, drools)
      .subscribe(predictions => {
        this.loading = false;
        this.predictions = predictions;
        this.computedPredictions = this.computePredictions(predictions, this.croppings);
        this.predictionFieldsCount = this.computePredictionFieldsCount(predictions);
      });
    const cropsFiltered = this.croppings.filter(crop => {
      return crop.cropId === this.cropId
    });
    this.selectedCrop = this.cropName.cropName(cropsFiltered[0].assignedCrop, cropsFiltered[0].assignedCrop.displayName, cropsFiltered[0].drools);

    this.croppingFieldsCount = cropsFiltered.length;
    this.trend = this.analysisService.getCropCutoff(this.cropId) > new Date();
  }
  calculatePredictionValue(key: string, yieldPredictionAverageOverDays: Array<{ key: string, value: number }>, fieldArea: number) {
    const avg = yieldPredictionAverageOverDays.find((predictionAverage) => predictionAverage.key === key);
    return avg && avg.value ? avg.value * fieldArea : 0;
  }

  computePredictionFieldsCount(predictions) {
    return predictions.reduce((acc, prediction) => {
      if (prediction.yieldPredictionAverageOverDays) {
        for (let i = 0; i < prediction.yieldPredictionAverageOverDays.length; i++) {
          let currentAverage = prediction.yieldPredictionAverageOverDays[i];
          if (currentAverage.value && currentAverage.value > 0) {
            return acc + 1;
          }
        }
      }
      return acc;
    }, 0);
  }

  computePredictions(predictions, croppings) {
    return predictions.reduce((totals, prediction) => {
      const cropping = croppings.find(croppingItem => croppingItem.field.id === prediction.id);
      const yieldPredictionAverageOverDays = prediction.yieldPredictionAverageOverDays;
      if (!cropping || !yieldPredictionAverageOverDays || yieldPredictionAverageOverDays.length === 0) {
        return totals;
      }

      return this.computePredictionTotals(totals, cropping, yieldPredictionAverageOverDays, cropping.field.zoneArea);
    }, getDefaultTotals());
  }

  private computePredictionTotals(totals, cropping, yieldPredictionAverageOverDays, area) {
    const latestPrediction = this.calculatePredictionValue('1', yieldPredictionAverageOverDays, area);
    totals.sevenDayAverage += this.calculatePredictionValue('7', yieldPredictionAverageOverDays, area);
    totals.twentyDayAverage += this.calculatePredictionValue('20', yieldPredictionAverageOverDays, area);
    totals.eightFifteenDayAverage += this.calculatePredictionValue('8-15', yieldPredictionAverageOverDays, area);
    totals.twentyTwentyDayAverage += this.calculatePredictionValue('20-20', yieldPredictionAverageOverDays, area);
    totals.fifteenFifteenAverage += this.calculatePredictionValue('15-15', yieldPredictionAverageOverDays, area);
    totals.latestPrediction += latestPrediction;
    totals.trend = totals.sevenDayAverage >= totals.eightFifteenDayAverage ? 'up' : 'down';
    totals.totalFieldsArea += latestPrediction ? area : 0;
    totals.totalRate = totals.latestPrediction / totals.totalFieldsArea;

    this.addPredictionByField(cropping, latestPrediction);

    return totals;
  }

  private addPredictionByField(cropping, latestPrediction) {
    let farmerCropIdx = this.predictionsByField.findIndex(farmerCrop => cropping.assignedCrop && farmerCrop.name === cropping.assignedCrop.name);
    if (farmerCropIdx === -1) {
      this.predictionsByField.push({
        name: cropping.field.name,
        value: latestPrediction
      });
    } else {
      this.predictionsByField[farmerCropIdx].value += latestPrediction;
    }
  }

  ngOnDestroy() {
  }

  showPrediction(averageName: string) {
    return this.predictions.every((p) => {
      let average = p.yieldPredictionAverageOverDays.find(avg => avg.key === averageName);
      return average && average.value && average.value > 0;
    });
  }
}
