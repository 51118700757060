import * as FarmersActions from '../actions/farmers.actions';
import { FarmersTypes, SortTypes } from '../actions/farmers.actions';
import { FarmerModel } from 'ag-space-common';
import { sortByName } from '../../../shared/pipes/order-by.pipe';

export type Action = FarmersActions.All;

export interface FarmersState {
  loading: boolean;
  error: string;
  farmers: Array<FarmerModel>;
  farmer: FarmerModel;
  farmersSorted: Array<FarmerModel>;
  cropHealthOrder: SortTypes;
}

const emptyFarmer = {
  name: '',
  id: null
};

const defaultState: FarmersState = {
  loading: false,
  error: null,
  farmer: emptyFarmer,
  farmers: [],
  farmersSorted: [],
  cropHealthOrder: SortTypes.NONE
};

const orderByHealthy = (list: Array<any>, order: SortTypes) => {
    if (list && list.length) {
    return list.slice(0).sort((a, b) => {
      if (!a.crops.length || !b.crops.length) {
        return;
      }
      //Sorting for type ASC should be done via noBiomass and for type DESC/NONE for healthy
      const sortValue = order === SortTypes.ASC ? 'noBiomass' : 'healthy';
      let comparison = 0;
      let itemA = a.crops[0].stats[`${sortValue}`],
        itemB = b.crops[0].stats[`${sortValue}`];
      if (itemA < itemB) {
        comparison = 1;
      } else if (itemA > itemB) { comparison = -1; }
      return  comparison //it should always go from bigger to lower
    });
  } else {
    return list;
  }
};

const sortFarmersByCropHealth = (list: Array<any>, order: SortTypes) => {
  if (!list || !list.length) {
    return list;
  }
  if (order === SortTypes.NONE) {
    return sortByName(list, 'name');
  } else {
    let cropsWithHealthy = [];
    let cropsWithoutData = [];
    list.forEach(farmer => {
      if (farmer.crops[0] && !farmer.crops[0].stats.healthy && !farmer.crops[0].stats.noBiomass) {
        cropsWithoutData.push(farmer);
      } else {
        cropsWithHealthy.push(farmer);
      }
    });
    cropsWithoutData = sortByName(cropsWithoutData, 'name');
    cropsWithHealthy = orderByHealthy(cropsWithHealthy, order);
    return cropsWithHealthy.concat(cropsWithoutData);
  }
};

export function farmersReducer(state: FarmersState = defaultState, action: Action): FarmersState {
  switch (action.type) {

    case FarmersTypes.GET_FARMER:
    case FarmersTypes.SEARCH_FARMERS: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case FarmersTypes.GET_FARMER_SUCCESS: {
      return {
        ... state,
        loading: false,
        farmer: action.farmer
      };
    }

    case FarmersTypes.GET_FARMERS: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case FarmersTypes.GET_FARMERS_SUCCESS: {
      let farmers = action.farmers;
      return {
        ... state,
        loading: false,
        farmers,
        farmersSorted: sortFarmersByCropHealth(farmers, state.cropHealthOrder)
      };
    }

    case FarmersTypes.GET_FARMER_FAILURE: {
      return {
        ...state,
        farmer: emptyFarmer,
        error: action.error,
        loading: false
      };
    }

    case FarmersTypes.GET_FARMERS_FAILURE: {
      return {
        ...state,
        error: action.error,
        farmers: [],
        loading: false
      };
    }

    case FarmersTypes.SET_FARMERS_SORTING: {
      return {
        ...state,
        cropHealthOrder: action.cropHealthOrder
      };
    }

    default: {
      return state;
    }
  }
};
