import * as AdvancedSearchAction from '../actions/advanced-search.actions';
import { AdvancedSearchTypes } from '../actions/advanced-search.actions';
import { SearchFilterModel } from '../../models/search-filter.model';
import { LocationModel } from '../../models/location.model';

export type Action = AdvancedSearchAction.All;

export interface AdvancedSearchState {
  loading: boolean;
  error: string;
  areas: LocationModel[];
  loadingAreas: boolean;
  searchObj: SearchFilterModel;
}

const defaultState: AdvancedSearchState = {
  loading: false,
  error: null,
  areas: [],
  loadingAreas: false,
  searchObj: null
};

export function advancedSearchReducer(state: AdvancedSearchState = defaultState, action: Action): AdvancedSearchState {
  switch (action.type) {

    case AdvancedSearchTypes.GET_AREAS: {
      return {
        ...state,
        loadingAreas: true
      };
    }

    case AdvancedSearchTypes.GET_AREAS_SUCCESS: {
      return {
        ...state,
        loadingAreas: false,
        areas: action.areas
      };
    }

    case AdvancedSearchTypes.RESET_AREAS: {
      return {
        ...state,
        areas: defaultState.areas
      };
    }

    case AdvancedSearchTypes.SEARCH_OBJECT: {
      return {
        ...state,
        searchObj: {...action.searchObj}
      };
    }

    case AdvancedSearchTypes.RESET_SEARCH_OBJECT: {
      return {
        ...state,
        searchObj: defaultState.searchObj
      };
    }

    default: {
      return state;
    }
  }
};
