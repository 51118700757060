import * as AuthActions from '../actions/auth.actions';
import { AuthTypes } from '../actions/auth.actions';

export type Action = AuthActions.All;

export interface AuthState {
  loggedIn: boolean;
  distributor: number;
  catalogueId: number;
  loading: boolean;
  error: string;
  user: any;
  distributors: any;
}

const defaultState: AuthState = {
  loggedIn: false,
  distributor: null,
  catalogueId: null,
  loading: false,
  error: null,
  user: {
    id: null,
    farmer: null,
    name: null,
    email: null,
    emailConfirmed: false,
    distributorId: null,
    deleted: false,
    language: null,
    roles: [],
    emailPopupSeen: false,
    firstName: null,
    lastName: null
  },
  distributors: []
};

export function authReducer(state: AuthState = defaultState, action: Action): AuthState {
  switch (action.type) {
    case AuthTypes.LOGIN: {
      return {
        ...state,
        loading: true
      };
    }

    case AuthTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        loading: false
      }
    }

    case AuthTypes.GET_CURRENT_USER_SUCCESS: {
      let distributor = action.user.distributorId;
      return {
        ...state,
        distributor,
        user: action.user
      };
    }

    case AuthTypes.LOGIN_FAILURE: {
      return {
        ...state,
        loggedIn: false,
        loading: false,
        error: action.error
      };
    }

    case AuthTypes.GET_DISTRIBUTORS: {
      return {
        ...state,
        loading: true
      };
    }

    case AuthTypes.GET_DISTRIBUTORS_SUCCESS: {
      return {
        ...state,
        loading: false,
        distributors: action.distributors
      };
    }

    case AuthTypes.GET_DISTRIBUTORS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }

    case AuthTypes.DISTRIBUTOR_UPDATE: {
      return {
        ...state,
        loading: false,
        distributor: action.distributor
      };
    }

    case AuthTypes.LOGOUT: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
}
