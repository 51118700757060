import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts-more-node';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { CropNameService } from '../../../core/providers/crop-name.service'
import { TranslateService } from '@ngx-translate/core';
import { throttleTime } from 'rxjs/operators';

export function addTooltip(self, ha) {
  return `<span style="font-size: 10px">${self.x}</span><br>
          <span style="color:${self.point.color}">\u25CF</span> ${self.series.name}: <b>${this.y.toFixed(2)}%</b> (${this.point.area.toFixed(2)} ${ha})<br/>`;
}

@Component({
  selector: 'crop-health-bar',
  template: `<div #chartTargetBar></div>`
})
export class CropHealthBarComponent implements OnInit, OnDestroy {
  chart: Highcharts.ChartObject;
  @ViewChild('chartTargetBar') chartTarget: ElementRef;
  @Input() chartData: any;
  @Input() chartName: string;
  maxBarWidth = 30;
  resize$: Observable<any>;
  resizeSubscription: Subscription;
  responsiveOptions = {
    rules: [{
      condition: {
        maxWidth: 400
      },
      chartOptions: {
        chart: {
          height: 300
        }
      }
    },
      {
        condition: {
          minWidth: 401
        },
        chartOptions: {
          chart: {
            height: 350
          }
        }
      }]
  };

  constructor(
    private translate: TranslateService,
    private cropName: CropNameService
  ) {
    this.resize$ = fromEvent(window, 'resize').pipe(throttleTime(300));
  }

  ngOnInit() {
      this.getCharts();
      this.resizeSubscription = this.resize$.subscribe(() => {
        this.getCharts();
      });
  }

  checkWidth(innerWidth) {
    if (innerWidth > 1024) {
      return innerWidth / 3;
    }
    if (innerWidth < 1024 && innerWidth > 600) {
      return innerWidth / 2;
    }
    if (innerWidth < 600) {
      return innerWidth * 0.9;
    }
  }

  getCharts() {
    this.chartData = this.chartData || {};
    let ha = this.translate.instant('LABELS.HA');
    this.chartData.noData = 0; // need dummy data to draw empty pie with 'no data
    this.chartData.noDataArea = 0; // need dummy data to draw empty pie with 'no data
    let xAxisCategories = [];
    let healthy = [];
    let lowBiomass = [];
    let noBiomass = [];
    let noData = [];
    this.chartData.forEach(item => {
        healthy.push({
          y: item.stats.healthy,
          area: item.stats.healthyCropArea,
          name: this.translate.instant('CHARTS.HEALTHY_BIOMASS'),
          index: 3,
          color: '#1fd60e',
        });
        lowBiomass.push({
          y: item.stats.lowBiomass,
          area: item.stats.lowBiomassArea,
          name: this.translate.instant('CHARTS.LOW_BIOMASS'),
          index: 2,
          color: '#f4b303'
        });
        noBiomass.push({
          y: item.stats.noBiomass,
          area: item.stats.noBiomassArea,
          name: this.translate.instant('CHARTS.NO_BIOMASS'),
          index: 1,
          color: '#db4437',
        });
        noData.push({
          y: this.displayEmptyData(item.stats),
          area: item.area,
          name: this.translate.instant('SHARED.NO_DATA'),
          data: noData,
          index: 1,
          color: '#cacaca',
          borderColor: '#e0e0e0',
          borderWidth: 1
        });
        const cropName = this.cropName.cropName(item, item.displayName, item.drool);
        xAxisCategories.push(cropName);
    });

    let series = this.buildSeries(healthy, lowBiomass, noBiomass, noData);

    this.chart = Highcharts.chart(this.chartTarget.nativeElement, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'bar',
        width: this.checkWidth(window.innerWidth),
        backgroundColor: null
      },
      legend: {
        reversed: true,
      },
      title: {
        text: this.chartName
      },
      tooltip: {
        formatter () {
          return addTooltip.call(this, this, ha);
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: xAxisCategories
      },
      yAxis: {
        title: null,
        max: 100
      },
      plotOptions: {
        series: {
          stacking: 'normal',
        }
      },
      series: series,
      responsive: this.responsiveOptions
    });
  }

  displayEmptyData(chartData) {
    if (!chartData.noBiomass && !chartData.lowBiomass && !chartData.healthy) {
      return this.chartData.noData = 100;
    }
  }

  buildSeries(healthy, lowBiomass, noBiomass, noData) {
    let list = [];
    if (healthy.some(item => item.y)) {
      list.push({
        data: healthy,
        name: this.translate.instant('CHARTS.HEALTHY_BIOMASS'),
        index: 3,
        color: '#1fd60e',
        maxPointWidth: this.maxBarWidth
      });
    }
    if (lowBiomass.some(item => item.y)) {
      list.push({
        data: lowBiomass,
        name: this.translate.instant('CHARTS.LOW_BIOMASS'),
        index: 2,
        color: '#f4b303',
        maxPointWidth: this.maxBarWidth
      });
    }
    if (noBiomass.some(item => item.y)) {
      list.push({
        data: noBiomass,
        name: this.translate.instant('CHARTS.NO_BIOMASS'),
        index: 1,
        color: '#db4437',
        maxPointWidth: this.maxBarWidth
      });
    }
    if (noData.some(item => item.y)) {
      list.push({
        data: noData,
        name: this.translate.instant('SHARED.NO_DATA'),
        index: 1,
        color: '#cacaca',
        borderColor: '#e0e0e0',
        borderWidth: 1,
        maxPointWidth: this.maxBarWidth
      });
    }
    return list;
  }

  ngOnDestroy() {
   this.resizeSubscription.unsubscribe();
  }

}
