import { Action } from '@ngrx/store';
import { CropGrowthModel } from 'ag-space-common';

export const CGIActionTypes = {
    LOAD: '[CGI] Load',
    LOAD_CGI_SUCCESS: '[CGI] Load CGI Success',
    LOAD_CGI_FAILURE: '[CGI] Load CGI Failure'
};

export class CGILoad implements Action {
    type = CGIActionTypes.LOAD;
    constructor() { }
}


export class CGILoadSuccess implements Action {
    type = CGIActionTypes.LOAD_CGI_SUCCESS;
    constructor(public lookup: any, public models: CropGrowthModel[]) { }
}

export class CGILoadFailure implements Action {
    type = CGIActionTypes.LOAD_CGI_FAILURE;
    constructor(public err: any) { }
}

export type CGIActions = CGILoadFailure | CGILoadSuccess;
