import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store/reducers';
import { ApiService } from 'ag-space-common';

@Component({
  selector: 'navigation-site',
  templateUrl: 'navigation.component.html',
  styleUrls: ['navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  loggedIn$: Observable<boolean>;
  activeRoute: string;

  constructor(
    private api: ApiService,
    private store: Store<State>,
    private router: Router
    ) {
  }

  ngOnInit() {
    this.loggedIn$ = this.store.select(store => store.auth.loggedIn);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        this.activeRoute = event.url;
      }
    });
  }

  goTo(selector: string) {
    let el = document.querySelector(`#${selector}`);
    if (el) {
      el.scrollIntoView({block: 'start', behavior: 'smooth',  inline: 'start'});
    }
  }

}
