import * as SeasonActions from '../actions/season.actions';
import { SeasonTypes } from '../actions/season.actions';

export type Action = SeasonActions.All;


const CURRENT_YEAR: number = new Date().getFullYear();
const YEARS = [
  CURRENT_YEAR + 1,
  CURRENT_YEAR,
  CURRENT_YEAR - 1,
  CURRENT_YEAR - 2,
  CURRENT_YEAR - 3,
  CURRENT_YEAR - 4
];
const CURRENT_MONTH: number = new Date().getMonth();


export interface SeasonState {
  year: number;
  season: number;
  years: number[];
  seasons: number[];
  loading: boolean;
  error: any;
}

/**
 * This is a helper function to check the year and season logic for grow.
 * This should be done using jasmine, but jasmine isn't working properly in the project and as this is a time critical fix,
 * I'm not going to set this up as part of this work.
 *
 * TODO - Get jasmine working and move to a jasmine format.
 */
export function checkDefaultYearAndSeason() {

  console.log('------------ Start of checkDefaultYearAndSeason');

  const assertSpecificYearAndMonth = function (inputYear, inputMonth, expectedSeason, expectedYear, goodStatus) {

    const currentInputValues = 'for month ' + inputMonth + ' year - ' + inputYear;

    const yearAndSeasonObject = getYearAndSeason(inputYear, inputMonth);
    if (yearAndSeasonObject.season !== expectedSeason) {
      console.log('Broken on season assertion - ' +  currentInputValues);
      goodStatus = false;
    }

    if (yearAndSeasonObject.year !== expectedYear) {
      console.log('Broken on year assertion -' +  currentInputValues);
      goodStatus = false;
    }
    return goodStatus;
  };

  let allGood = true;

  // Jan
  allGood = assertSpecificYearAndMonth(2020, 0, 1, 2020, allGood);
  // Feb
  allGood = assertSpecificYearAndMonth(2020, 1, 1, 2020, allGood);
  // Mar
  allGood = assertSpecificYearAndMonth(2020, 2, 1, 2020, allGood);
  // Apr
  allGood = assertSpecificYearAndMonth(2020, 3, 1, 2020, allGood);
  // May
  allGood = assertSpecificYearAndMonth(2020, 4, 1, 2020, allGood);
  // June
  allGood = assertSpecificYearAndMonth(2020, 5, 2, 2020, allGood);
  // Jul
  allGood = assertSpecificYearAndMonth(2020, 6, 2, 2020, allGood);
  // Aug
  allGood = assertSpecificYearAndMonth(2020, 7, 2, 2020, allGood);
  // Sept
  allGood = assertSpecificYearAndMonth(2020, 8, 2, 2020, allGood);
  // Oct
  allGood = assertSpecificYearAndMonth(2020, 9, 1, 2021, allGood);
  // Nov
  allGood = assertSpecificYearAndMonth(2020, 10, 1, 2021, allGood);
  // Dec
  allGood = assertSpecificYearAndMonth(2020, 11, 1, 2021, allGood);

  if (allGood) {
    console.log('Everything is working okay');
  }

  console.log('------------ End of checkDefaultYearAndSeason');
}


export function getDefaultYearAndSeason() {
  // As we don't have jasmine in the project that works, this can be uncommented to check year and season logic works correctly.
  // checkDefaultYearAndSeason();
  return getYearAndSeason(CURRENT_YEAR, CURRENT_MONTH);
}

export function getYearAndSeason(year, month) {
  // Between June & September
  if (month >= 5 && month <= 8) {
    return {
      year: year,
      season: 2
    };
  }
  // Before June
  if (month < 5) {
    return {
      year: year,
      season: 1
    };
  }

  // After September
  if (month > 8) {
    return {
      year: year + 1,
      season: 1
    };
  }
}

const defaultState: SeasonState = {
  year: getDefaultYearAndSeason().year,
  season: getDefaultYearAndSeason().season,
  years: YEARS,
  seasons: [1],
  loading: false,
  error: null
};

export function seasonReducer (state: SeasonState = defaultState, action: Action): SeasonState {
  switch (action.type) {

    case SeasonTypes.UPDATE: {
      return {
        ...state,
        year: action.year,
        season: action.season
      };
    }

    case SeasonTypes.SET_DEFAULT: {
      return defaultState;
    }

    case SeasonTypes.GET_SEASONS: {
      return {
        ...state,
        loading: true
      };
    }

    case SeasonTypes.GET_SEASONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        seasons: action.seasons === 1 ? [1] : [1, 2],
        season: action.seasons === 1 ? 1 : defaultState.season
      };
    }

    case SeasonTypes.GET_SEASONS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }

    default: {
      return state;
    }
  }
};
