import * as ChartsActions from '../actions/field.actions';
import { FieldTypes } from '../actions/field.actions';

export type Action = ChartsActions.All;

export interface FieldState {
  loading: boolean;
  error: string;
  fieldCropHealth: {};
  fieldCropHealthDate: number;
}

const defaultState: FieldState = {
  loading: false,
  error: null,
  fieldCropHealth: {},
  fieldCropHealthDate: null
};


export function fieldReducer (state: FieldState = defaultState, action: Action): FieldState {
  switch (action.type) {

    case FieldTypes.FIELD_CROP_HEALTH: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case FieldTypes.FIELD_CROP_HEALTH_SUCCESS: {
      return {
        ... state,
        loading: false,
        fieldCropHealth: action.cropHealth.ndviStats,
        fieldCropHealthDate:  new Date(action.cropHealth.date).getTime() || action.cropHealth.date
      };
    }

    case FieldTypes.FIELD_CROP_HEALTH_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
