import { RouterModule, Routes } from '@angular/router';

import { FarmComponent } from './farm/farm.component';
import { GrowersComponent } from './growers/growers.component';
import { CropsComponent } from './crops/crops.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { FarmGuardService } from './core/providers/farm-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found..component';
import { AuthGuard } from 'auth-lib';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'growers', pathMatch: 'full'},
      { path: 'growers',
        children: [
          { path: '', component: GrowersComponent },
          { path: ':farmerId', children: [
            { path: '', component: GrowersComponent },
            {
              path: 'farms',
              children: [
                { path: '', component: GrowersComponent },
                { path: ':id', canActivate: [FarmGuardService], component: FarmComponent }
              ]
            }
          ]}
        ]
      },
      { path: 'crops', component: CropsComponent },
      // { path: 'nutrients', component: NutrientsComponent },
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '404', component: PageNotFoundComponent },
  // { path: 'landing', component: LandingComponent }
];

export const routing = RouterModule.forRoot(routes);
