
import { of as observableOf } from 'rxjs';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    Get,
    GetSuccess,
    GetFailure,
    CroppingTypes,
    GetCropsByFarm,
    GetCropsByFarmSuccess,
    GetCropsByFarmFailure
} from '../actions/crops.actions';
import { DistributorService } from '../../providers/distributor.service';

@Injectable()
export class CropsEffects {

    @Effect() get$ = this.actions$
        .pipe(ofType(CroppingTypes.GET),
          switchMap((action: Get) => this.distributorService.getCropTotals(action.distributor, action.year, action.season).pipe(
            mergeMap(crops => observableOf(new GetSuccess(crops))),
            catchError(err => observableOf(new GetFailure(err))), )
        ));

    @Effect() getCropsByFarm$ = this.actions$
      .pipe(ofType(CroppingTypes.GET_CROPS_BY_FARM),
        switchMap((action: GetCropsByFarm) => this.distributorService.getCroppingData(action.farmId, action.season, action.year).pipe(
        mergeMap(cropsByFarm => observableOf(new GetCropsByFarmSuccess(cropsByFarm))),
        catchError(err => observableOf(new GetCropsByFarmFailure(err))), )
      ));

    constructor(
        private actions$: Actions,
        private distributorService: DistributorService
    ) {
    }

}
