import * as ObservationsActions from '../actions/observations.actions';
import { ObservationsTypes } from '../actions/observations.actions';
import { ObservationModel } from 'ag-space-common';

export type Action = ObservationsActions.All;

export interface ObservationsState {
  loading: boolean;
  error: string;
  observations: Array<ObservationModel>;
}

const defaultState: ObservationsState = {
  loading: false,
  error: null,
  observations: []
};


export function observationsReducer (state: ObservationsState = defaultState, action: Action): ObservationsState {
  switch (action.type) {

    case ObservationsTypes.GET: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case ObservationsTypes.GET_SUCCESS: {
      return {
        ... state,
        loading: false,
        observations: action.observations
      };
    }

    case ObservationsTypes.GET_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false
      };
    }

    case ObservationsTypes.RESET: {
      return {
        ...state,
        loading: false,
        observations: []
      };
    }

    default: {
      return state;
    }
  }
};
