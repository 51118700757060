import { Action } from '@ngrx/store';

export enum CatalogueActionTypes {
  GET_CATALOGUE = '[Catalogue] Get',
  LOAD_CATALOGUE_FAILURE = '[Catalogue] Load Catalogue Failure',
  LOAD_CATALOGUE_SUCCESS = '[Catalogue] Load Catalogue Success'
}

export class CatalogueLoad implements Action {
  readonly type = CatalogueActionTypes.GET_CATALOGUE;
  constructor( public distributor: number ) {}
}

export class CatalogueLoadSuccess implements Action {
  readonly type = CatalogueActionTypes.LOAD_CATALOGUE_SUCCESS;
  constructor(public catalogue: any) { }
}

export class CatalogueLoadFailure implements Action {
  readonly type = CatalogueActionTypes.LOAD_CATALOGUE_FAILURE;
  constructor(public err: any) { }
}

export type CatalogueActions = CatalogueLoad | CatalogueLoadSuccess | CatalogueLoadFailure;
