
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
  FarmTypes,
  Get,  GetSuccess,  GetFailure,
  GetCroppingTotals, GetCroppingTotalsSuccess, GetCroppingTotalsFailure
} from '../actions/farm.actions';
import { DistributorService } from '../../providers/distributor.service';

@Injectable()
export class FarmEffects {

  @Effect() get$ = this.actions$
    .pipe(ofType(FarmTypes.GET),
      switchMap((action: Get) => this.distributorService.getFarm(action.id).pipe(
      mergeMap(farm => observableOf(new GetSuccess(farm))),
      catchError(err => observableOf(new GetFailure(err))),)
    ));

  @Effect() getCroppingTotals$ = this.actions$
    .pipe(ofType(FarmTypes.GET_CROPPING_TOTALS),
      switchMap((action: GetCroppingTotals) => this.distributorService.getFarmCroppingTotals(action.id, action.year, action.season).pipe(
      mergeMap(farm => observableOf(new GetCroppingTotalsSuccess(farm))),
      catchError(err => observableOf(new GetCroppingTotalsFailure(err))),)
    ));

  constructor(
    private actions$: Actions,
    private distributorService: DistributorService
  ) {
  }

}
