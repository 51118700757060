import { Action } from '@ngrx/store';

import { CropTotalsModel } from '../../models/crop-totals.model';

export enum CroppingTypes {
  GET = '[Crops] Get',
  GET_SUCCESS = '[Crops] Get Success',
  GET_FAILURE = '[Crops] Get Failure',
  GET_CROPS_BY_FARM = '[Crops by farms] Get',
  SORT = '[Crops] Sort',
  GET_CROPS_BY_FARM_SUCCESS = '[Crops by farms] Get Success',
  GET_CROPS_BY_FARM_FAILURE = '[Crops by farms] Get Failure'
}


export class Get implements Action {
    readonly type = CroppingTypes.GET;
    constructor(public distributor: number, public year: number, public season: number) { }
}

export class GetSuccess implements Action {
    readonly type = CroppingTypes.GET_SUCCESS;
    constructor(public crops: CropTotalsModel[]) { }
}

export class GetFailure implements Action {
    readonly type = CroppingTypes.GET_FAILURE;
    constructor(public error: any) { }
}

export class Sort implements Action {
    readonly type = CroppingTypes.SORT;
    constructor(public column: string, public ascending = true) { }
}

export class GetCropsByFarm implements Action {
  readonly type = CroppingTypes.GET_CROPS_BY_FARM;
  constructor(public farmId: number, public year: number, public season: number) { }
}

export class GetCropsByFarmSuccess implements Action {
  readonly type = CroppingTypes.GET_CROPS_BY_FARM_SUCCESS;
  constructor(public cropsByFarmId: any) { }
}

export class GetCropsByFarmFailure implements Action {
  readonly type = CroppingTypes.GET_CROPS_BY_FARM_FAILURE;
  constructor(public error: any) { }
}

export type All = Get | GetSuccess | GetFailure | Sort | GetCropsByFarm | GetCropsByFarmSuccess | GetCropsByFarmFailure;
