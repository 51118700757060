
import { takeUntil, map, withLatestFrom} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../core/providers/modal.service';
import { Store } from '@ngrx/store';
import { State } from '../core/store/reducers';
import { GetFieldCropHealth } from '../core/store/actions/field.actions';
import { Observable ,  Subject ,  BehaviorSubject } from 'rxjs';
import { FarmModel, ImageryTypes, ZoneModel, AnalysisService, FieldModel, ImageryGroup, TimeRangeModel, AgsDateTimeService } from 'ag-space-common';
import uniqBy from 'lodash-es/uniqBy';

@Component({
  selector: 'field-details',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit, OnDestroy {
  fieldChart$: Observable<any>;
  soilAnalysis$: Observable<any>;
  farmId: number;
  location = null;
  zones: ZoneModel[];
  field: FieldModel;
  selectedZone: ZoneModel;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  farm$: Observable<FarmModel>;
  loadingChart$: Observable<any>;
  fieldCropHealthDate$: Observable<any>;
  selectedImageryType: any;
  public imageryTypes = ImageryTypes;
  sarCrops$: Observable<any>;
  year$: Observable<any>;
  season$: Observable<any>;
  hideLegendNumbersFor$: Observable<any>;
  showSoilAnalysis$: Observable<boolean>;
  timeRangeSubj = new BehaviorSubject<TimeRangeModel>(null);
  plotLines: number = null;
  data: any;

  constructor(private modalService: ModalService, private store: Store<State>,
              private analysisService: AnalysisService, private dateTimeService: AgsDateTimeService) {
  }

  ngOnInit() {
    let data = this['data'];
    this.farmId = data.farmId;
    this.zones = data.field.zones;
    this.field = data.field;
    this.farm$ = data.farm$;
    this.farm$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(farm => this.location = {lat: farm.lat, lon: farm.lon});

    this.year$ = this.store.select(state => state.season.year);
    this.season$ = this.store.select(state => state.season.season);
    this.selectedZone = this.zones[0];
    this.store.dispatch(new GetFieldCropHealth(this.field.id));
    this.fieldChart$ = this.store.select(state => state.field.fieldCropHealth);
    this.fieldCropHealthDate$ = this.store.select(state => state.field.fieldCropHealthDate);
    this.loadingChart$ = this.store.select(state => state.field.loading);
    this.soilAnalysis$ = this.analysisService.getRecentSoilAnalysis(this.farmId);
    this.hideLegendNumbersFor$ = this.store.select(state => state.map.hideLegendNumbersFor);
    this.showSoilAnalysis$ = this.soilAnalysis$.pipe(map(item => !!item.zones.length));
    this.sarCrops$ =  this.store.select(state => state.crops.cropsByFarm).pipe(withLatestFrom(
      this.store.select(state => state.cgi.cropModelIndex),
      (croppings, cgiIndex) => {
        const cropIds = Object.keys(cgiIndex).map(k => +k);

        const filtered = Object.values(croppings).filter(cropping => {
          return cropping.plantingDate && cropIds.indexOf(cropping.cropId) !== -1 && cropping.field.id === this.field.id;
        });

        return uniqBy(filtered, 'cropId').map((cropping: any) => ({ name: cropping.assignedCrop.name, displayName: cropping.assignedCrop.displayName, value: cropping.cropId }));
      }));

    this.store.select(state => state.crops.cropsByFarm).pipe(
      map(croppings => {
          let filtered = Object.values(croppings)
            .filter(cropping => {
              return cropping.cropping && cropping.cropping['plantingDate'] && cropping.cropping['crop'].id === this['data'].zone.crop.id && cropping.field.id === this['data'].zone.fieldId
            });
        return !!filtered.length ? filtered[0].plantingDate : null;
        }
      ),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(plantingDate => {
        this.plotLines = null;
        let startDate = this.dateTimeService.getMyDate(new Date(), -231).epoc,
          endDate = this.dateTimeService.getMyDate(new Date(), -1).epoc;

        if (plantingDate) {
          let dates = plantingDate.split('-').map(el => Number(el)),
            plantingDateTime = new Date(dates[0], dates[1] - 1, dates[2]),
            myPlantingDate = this.dateTimeService.getMyDate(plantingDateTime);

          this.plotLines = plantingDateTime.getTime();
          startDate = this.dateTimeService.getMyDate(plantingDateTime, -14).epoc;
          if (myPlantingDate.epoc < this.dateTimeService.getMyDate(new Date(), -240).epoc) {
            endDate = this.dateTimeService.getMyDate(plantingDateTime, 240).epoc;
          }
        }

      this.timeRangeSubj.next({
        startDate,
        endDate,
        timeFrame: 'DAILY'
      });
    });
  }

  updateSelectedZone(zone: ZoneModel) {
    this.selectedZone = zone;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  close() {
    this.modalService.close();
  }

  handleMapChange($event) {
    this.selectedImageryType = {
      type: $event.type,
      group: $event.group,
      option: $event.option
    };
  }

  handleHideLegendLabels(group: ImageryGroup, hideGroupFromStore) {
    return group && group.key && hideGroupFromStore && hideGroupFromStore.group === group.key;
  }
}

