import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from '../../../core/store/reducers';
import { Update } from '../../../core/store/actions/season.actions';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'season-select',
    templateUrl: './season-select.component.html'
})
export class SeasonSelectComponent implements OnInit, OnDestroy {

    years: Array<number>;
    seasons = [];
    year: number;
    season: number;
    ngUnsubscribe: Subject<void> = new Subject();

    constructor(private store: Store<State>) {
    }

    ngOnInit() {
        this.store.select(state => state.season)
          .pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe((season) => {
                this.year = season.year;
                this.season = season.season;
                this.seasons = season.seasons;
                this.years = season.years;
            });
    }

    changedYear(year) {
        this.year = year;
        this.changed();
    }
    changedSeason(season) {
        this.season = season;
        this.changed();
    }

    changed() {
        this.store.dispatch(new Update(this.year, this.season));
    }

    ngOnDestroy() {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
}
