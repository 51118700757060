import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { EnvironmentService } from './app/environment.service';


// depending on the env mode, enable prod mode or add debugging modules
if (environment.production) {
  enableProdMode();
}

EnvironmentService.loadConfig().then(() => {
  return platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
