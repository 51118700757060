import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class CropNameService {

  constructor(private translate: TranslateService) {}

  cropName(crop, name, drools) {
    let cropName = this.translate.instant('SHARED.UNALLOCATED');
    if (crop && !drools) {
      cropName = name;
    } else if (crop && drools) {
      cropName = name + ' (' + drools.value + ')';
    }
    return cropName;
  }
}
