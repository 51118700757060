import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../core/providers/modal.service';
import { Observable } from 'rxjs';
import { ImageModel } from '../../../core/models/image.model';

@Component({
  selector: 'image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss']
})
export class ImageViewComponent implements OnInit {
  image$: Observable<ImageModel>;

  constructor(private modalService: ModalService ) {}

  ngOnInit() {
    let data = this['data'];
    this.image$ = data;
  }

  close() {
    this.modalService.close();
  }


}

