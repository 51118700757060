import { Action } from '@ngrx/store';

export enum ObservationsTypes {
  GET = '[OBSERVATIONS] Get',
  GET_SUCCESS = '[OBSERVATIONS] Get Success',
  GET_FAILURE = '[OBSERVATIONS] Get Failure',
  RESET = '[OBSERVATIONS] Reset',
}

export class GetObservations implements Action {
  readonly type = ObservationsTypes.GET;
  constructor(public id: number) { }
}

export class GetObservationsSuccess implements Action {
  readonly type = ObservationsTypes.GET_SUCCESS;
  constructor(public observations: any, public farmId: number) { }
}

export class GetObservationsFailure implements Action {
  readonly type = ObservationsTypes.GET_FAILURE;
  constructor(public error: any) { }
}

export class ResetObservations implements Action {
  readonly type = ObservationsTypes.RESET;
  constructor() { }
}
export type All = GetObservations | GetObservationsSuccess | GetObservationsFailure | ResetObservations;
