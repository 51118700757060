import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store/reducers';
import { Observable, Subscription } from 'rxjs';
import { SearchFilterModel } from '../../../core/models/search-filter.model';
import { GetArea, ResetArea, ResetSearchFilterObject, UpdateSearchFilterObject } from '../../../core/store/actions/advanced-search.actions';
import { TranslateService } from '@ngx-translate/core';
import { SetFarmersSorting, SortTypes } from '../../../core/store/actions/farmers.actions';


@Component({
    selector: 'advanced-search',
    templateUrl: './advanced-search.component.html',
    styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit, OnDestroy {
  @Output() onRequestSubmit = new EventEmitter();

  searchObj: SearchFilterModel;
  loading$: Observable<any>;
  targetYieldError: boolean;
  farmSizeError: boolean;
  areas$: Observable<any>;
  cropHealthOrder$: Observable<SortTypes>;
  cropsList = [];
  cropsSub: Subscription;
  searchObjectSub: Subscription;
  cropError: boolean;
  regionError: boolean;
  areaError: boolean;
  loadingAreas$: Observable<boolean>;
  loadingGrowers$: Observable<boolean>;
  firstItemInDropdownId = -1;

  constructor(private store: Store<State>, private translate: TranslateService ) {}

  ngOnInit() {
    this.searchObj = this.getDefaultObj();
    this.loadingAreas$ = this.store.select(state => state.advancedSearch.loadingAreas);
    this.cropHealthOrder$ = this.store.select(state => state.farmers.cropHealthOrder);
    this.loadingGrowers$ = this.store.select(state => state.farmers.loading);
    this.loading$ = this.store.select(state => state.advancedSearch.loading);
    this.areas$ = this.store.select(state => state.advancedSearch.areas);
    this.store.select(state => state.catalogue).subscribe(val => {
      this.cropsList = val.catalogue;
    });

    this.searchObjectSub = this.store.select(state => state.advancedSearch.searchObj).subscribe(searchObj => {
      if (searchObj) {
        this.searchObj = JSON.parse(JSON.stringify(searchObj));
      }
    });
    window.addEventListener('keydown',  this.preventEnterKeydown, false);
  }

  preventEnterKeydown(ev) {
    if (ev.which === 13 || ev.keyCode === 13) {
      ev.preventDefault();
    }
  }

  getDefaultObj = () => ({
    distributorId: null,
    year: null,
    season: null,
    farmSize: {
      from: null,
      to: null
    },
    targetYield: {
      from: null,
      to: null
    },
    displayName: null,
    cropId: null,
    locationFilter: {
      id: null,
      type: null
    }
  });

  search() {
    this.checkIfValid();
    if (!this.farmSizeError && !this.targetYieldError && !this.cropError && !this.areaError) {
      if (this.isSearchObjectEmpty(this.searchObj)) {
        this.resetErrors();
        this.resetFilters();
      } else {
        this.store.dispatch(new UpdateSearchFilterObject(this.searchObj));
      }
      this.onRequestSubmit.emit({
        requestSent: true,
        searchObject: this.searchObj
      });
    }
  }

  resetForm() {
    this.searchObj = this.getDefaultObj();
    this.resetErrors();
    this.store.dispatch(new ResetArea());
  }

  private resetErrors() {
    this.farmSizeError = false;
    this.targetYieldError = false;
    this.cropError = false;
    this.areaError = false;
  }

  private resetFilters() {
    this.store.dispatch(new ResetSearchFilterObject());
    this.store.dispatch(new ResetArea());
  }

  checkIfValid() {
    this.searchObj.farmSize.from = this.searchObj.farmSize.from === 0 ? null : this.searchObj.farmSize.from;
    this.searchObj.farmSize.to = this.searchObj.farmSize.to === 0 ? null : this.searchObj.farmSize.to;
    this.searchObj.targetYield.from = this.searchObj.targetYield.from === 0 ? null : this.searchObj.targetYield.from;
    this.searchObj.targetYield.to = this.searchObj.targetYield.to === 0 ?  null : this.searchObj.targetYield.to;
    if (this.searchObj.farmSize.from && this.searchObj.farmSize.to) {
      this.farmSizeError = this.searchObj.farmSize.from >= this.searchObj.farmSize.to;
    }
    if (this.searchObj.targetYield.from && this.searchObj.targetYield.to) {
      this.targetYieldError = this.searchObj.targetYield.from >= this.searchObj.targetYield.to;
    }
  }

  /*
    Check if searchObject is empty (searchObject != null), or its property such as locationFilter, farmSize etc, if present in searchObject, also != null, otherwise
    searchObject is not empty
    @params searchObject
    @return boolean
   */
  isSearchObjectEmpty(searchObject) {
    return !searchObject || (!searchObject.cropId &&
      (!searchObject.locationFilter || !searchObject.locationFilter.id) &&
      ((!searchObject.farmSize && !searchObject.targetYield ) ||
        (!searchObject.farmSize.from && !searchObject.farmSize.to && !searchObject.targetYield.from && !searchObject.targetYield.to)));
  }

  selectCrop(val) {
    this.searchObj.cropId = val && val.id ? val.id : null;
    this.searchObj.displayName = val && val.displayName ? val.displayName : null;
    if (!this.searchObj.cropId || this.searchObj.cropId === 0) { return; }
    if (this.searchObj.cropId === -1) {
      this.store.dispatch(new SetFarmersSorting(SortTypes.NONE));
    } else {
      this.store.dispatch(new SetFarmersSorting(SortTypes.DESC));
    }
  }

  selectArea(val) {
    if (val && val.id) {
      this.searchObj.locationFilter = val;
    } else {
      this.searchObj.locationFilter = {id: null, type: null};
    }
  }

  getCropError(val) {
    this.cropError = val;
  }

  getAreaError(val) {
    this.areaError = val;
  }

  getSearchValue(val) {
    if (val.length > 2) {
      this.store.dispatch(new GetArea(val));
    } else {
      this.store.dispatch(new ResetArea());
    }
  }

  setCropHealthOrder(order: SortTypes) {
    this.store.dispatch(new SetFarmersSorting(order));
  }

  ngOnDestroy() {
    if (this.searchObjectSub) { this.searchObjectSub.unsubscribe(); }
    if (this.cropsSub) { this.cropsSub.unsubscribe(); }
    window.removeEventListener('keydown', this.preventEnterKeydown, false);
  }

}
