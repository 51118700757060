
import { switchMap,  map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store/reducers';
import { ApiService, FarmModel } from 'ag-space-common';
import { combineLatest, Observable } from 'rxjs';
import * as compress from 'graphql-query-compress';
import { HttpHeaders } from '@angular/common/http';

export const UNCROPPED_LAND_CROPID = 146;

const webservicesGrowise = '/api/growise/v2.0/';

@Injectable()
export class DistributorService {
  private urlApiBase = `{k8sUrl}/api/root/v2.0`;
  private croppingData = `{k8sUrl}/api/cropping/v2.0`;
  fields = [];

  constructor(private api: ApiService, private store: Store<State>) {

  }

  graphqlGrowise(query: string): Observable<any> {
    let options = {
      url: '{k8sUrl}' + webservicesGrowise + 'graphql',
      method: 'POST',
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: `{"query": "{${compress(query)}}"}`
    };

    return this.api.request(options);
  }

  getCropTotals(distributorId: number, year: number, season: number) {
    let query = `
        croppingTotals(distributor: ${distributorId}, year: ${year}, season: ${season}) {
            crop {
              id
              name
              displayName
              drool {
                id
                value
                name
              }
            }
            area
            target
            predictedYield
            farms {
                id
                name
                area
                target
                predictedYield
                farmer {
                  id
                  name
                }
            }
        }
        `;
    return this.graphqlGrowise(query).pipe(map(result => result.data.croppingTotals));
  }

  getNutrientTotals(year: number, season: number) {
    let query = `
            productTotals(year: ${year}, season: ${season}) {
                product {
                    name,
                    id
                },
                area,
                plannedRate,
                plannedQuantity,
                actualQuantity,
                farms {
                    id,
                    name,
                    area,
                    plannedRate,
                    plannedQuantity,
                    actualQuantity
                }
            }
        `;
    return this.graphqlGrowise(query).pipe(map(result => result.data.productTotals));
  }

  getFarm(farmId: number): Observable<FarmModel> {
    const url = `${this.urlApiBase}/graphql`;
    const query = `
    farms(where:{id: ${farmId}}){
      id,
      lat,
      lon,
      name,
      area,
      address,
      attributes{
        key,
        value
      },
      organic,
      farmer {
        name,
        id
      }
    }`;
    return this.api.graphql(url, query)
      .pipe(
        map(data => data.data.farms),
        map(farms => {
          if (farms.length > 0) {
            return farms[0];
          }
          return null;
        })
      );
  }

  getFarmCroppingTotals(farmId: number, year: number, season: number) {
    let query = `
      croppingTotalsForFarm(farm:${farmId}, year:${year}, season:${season}){
        crops
        {
          id
          name
          area
          displayName
          drool {
            id
            value
            name
          }
        },
        totalAreaCropped
        id
        name
        address
      }`;
    return this.graphqlGrowise(query).pipe(map(result => {
      let croppingTotalsForFarm = result.data.croppingTotalsForFarm;
      return croppingTotalsForFarm.length > 0 ? croppingTotalsForFarm[0] : null;
    }));
  }

  getFarms() {
    return this.store.select(state => state.auth.distributor).pipe(switchMap(id => {
      const url = `${this.urlApiBase}/graphql`;
      const query = `
            farms(where:{
              farmer:{deleted: false, distributor:{id:${id}}},
              deleted: false
            }) {
                id
                lat
                lon
                area
                name
                farmer {
                    distributor {
                        id
                    }
                    name
                },
                address
            }`;

      return this.api.graphql(url, query).pipe(
        map(data => data.data.farms),
        map(farms => farms.filter(farm => farm.lat && farm.lon)))
      }));
  }

  getFarmers(id, year, season) {
    let query = `
         croppingTotalsByFarmer(distributor:${id}, year:${year}, season:${season}) {
         crops {
           id
           name
           date
           area
           displayName
           drools {
            id
            value
            name
          }
           stats {
              noBiomass
              lowBiomass
              healthy
              noBiomassArea
              lowBiomassArea
              healthyCropArea
            }
         },
         totalAreaCropped,
         totalArea,
         totalAreaUncropped,
         id,
         name
    }
        `;
    return this
      .graphqlGrowise(query).pipe(
      map(result => {
        return result.data.croppingTotalsByFarmer.map(farmer => farmer);
      }));
  }

  getFarmer(distributorId, farmerId) {
    const url = `${this.urlApiBase}/graphql`;
    const query = `
      farmers(where:{ distributor:{id: ${distributorId} }, id: ${farmerId} }){
          id
          name
      }`;

    return this.api.graphql(url, query).pipe(
      map(result => result.data.farmers[0])
    );
  }

  getFarmsByGrower(id, year = null, season = null) {
    let query = `
        farmsInfoForFarmer(year: ${year}, season: ${season}, farmer: ${id}) {
          name,
          id,
          crops {
            date,
            area,
            name,
            id,
            stats {
              lowBiomassArea,
              lowBiomass,
              healthy,
              healthyCropArea,
              noBiomass,
              noBiomassArea
              }
            }
            area,
            address,
            lon,
            lat
          }
        `;
    return this.graphqlGrowise(query).pipe(
      map(result => result.data.farmsInfoForFarmer.filter(farm => {
        return farm.lat && farm.lon;
      })));
  }

  getFieldCropHealth(fieldId) {
    let query = `
     cropHealthByField(field: ${fieldId}) {
      fieldId
      date
      ndviStats {
        noBiomass
        lowBiomass
        healthy
        noBiomassArea
        lowBiomassArea
        healthyCropArea
      }
    }
    `;
    return this.graphqlGrowise(query).pipe(
      map(result => {
        return result.data.cropHealthByField;
      }),
      map(crop => {
        if (crop.date && crop.date.toLowerCase() === 'none') {
          crop.date = null;
        }
        return crop;
      }), );
  }

  getCroppingData(farmId, seasonId, year) {
    return  combineLatest(this.croppingsRequest(farmId, seasonId, year), this.getFieldsWithZoneArea(farmId)).pipe(
      map(([resp, fields]) => {
        return this.prepareResponse(resp, fields);
      }));
  }

  croppingsRequest(farmId, seasonId, year) {
    const body = {
      farmId,
      seasonId,
      year,
      paging: {page: 0, size: 1000, order: {field: 'field.name', direction: 'asc'}},
      filter: {}
    };
    return this.api.request({
      url: `${this.croppingData}/croppings/data`,
      method: 'POST',
      body: body
    });
  }

  prepareResponse(resp, fields) {
    let crops = {};
    resp.data.forEach(crop => {
      crop.field.zoneArea = this.findFieldArea(fields, crop.field.id).area;
      if (crop.cropping) {
        const cropped = crop.cropping.crop.id && crop.cropping.crop.id !== UNCROPPED_LAND_CROPID;
        crop.cropId = cropped ? crop.cropping.crop.id : UNCROPPED_LAND_CROPID;
        crops[crop.field.id] = {
          field: crop.field,
          cropId: crop.cropping.crop.id,
          year: crop.year,
          season: {id: crop.seasonId, name: 'Season ' + crop.seasonId},
          id: crop.cropping.id,
          assignedCrop: crop.cropping.crop,
          plantingDate: crop.cropping.plantingDate,
          drools: crop.cropping.drools
        };
      } else {
        crops[crop.field.id] = {
          field: crop.field,
          cropId: UNCROPPED_LAND_CROPID,
        };
      }
    });
    return crops;
  }

  findFieldArea(fields, cropFieldId) {
    return fields.find((element) => {
      return element.id === cropFieldId;
    });

  }

  getFieldsWithZoneArea(farmId: number) {
    const urlBase = `{k8sUrl}/api/root/v2.0/graphql`;
    const query = `
      fields(where:{layer:{ farm: {id: ${farmId}}}})
        {
          id,
          area: zoneArea,
          name,
          irrigationAllowed
        }
      `;
    return this.api.graphql(urlBase, query).pipe(
      map(response => {
        this.fields = response.data.fields;
        return response.data.fields;
      })
    );
  }

  getCroppingsForFarm(farmId: number, year: number, season: number) {
    const url = `${this.croppingData}/graphql`;
    const query = `
       croppings (farmId:${farmId}, year: ${year}, seasonId: ${season}) {
          id,
          year,
          plantingDate,
          cropId,
          assignedCrop {
            id,
            name
          }
          season {
            id,
            name
          },
          field {
              name,
              id,
              zoneArea
          }
        }`;


    return this.api.graphql(url, query).pipe(
      map(resp => {
        let crops = {};
        resp.data.croppings.forEach(crop => {
          const cropped = crop.cropId && crop.cropId !== UNCROPPED_LAND_CROPID;
          crop.cropId = cropped ? crop.cropId : UNCROPPED_LAND_CROPID;
          crops[crop.field.id] = crop;
        });
        return crops;
      }));
  }

  getFarmerDistributors(farmerId) {
    let query = `
      distributorsForFarmer(farmer:${farmerId}){id name cropCatalogueId}
    `;

    return this.graphqlGrowise(query).pipe(map(result => {
      return result.data.distributorsForFarmer;
    }));
  }

  getSeasons(distributorId) {
    const url = `${this.urlApiBase}/graphql`;
    const query = `distributors(where:{id:${distributorId}}){seasons}`;

    return this.api.graphql(url, query).pipe(
      map(result => result.data.distributors[0].seasons)
    );
  }

  getArea(searchInput: string) {
    return this.api.request({
      url: '{k8sUrl}' + webservicesGrowise + 'find/farm-address',
      method: 'POST',
      body: {input: searchInput}
    });
  }

  search(searchObject) {
    return this.api.request({
      url: '{k8sUrl}' + webservicesGrowise + 'filtered/growers',
      method: 'POST',
      body: searchObject
    });
  }

  searchFarms(searchObject, farmerId) {
    let obj = {...searchObject};
    obj['farmerId'] = farmerId;
    return this.api.request({
      url: '{k8sUrl}' + webservicesGrowise + 'filtered/farms',
      method: 'POST',
      body: obj
    }).pipe(map(result => result.filter(farm => {
      return farm.lat && farm.lon;
    }).map(farm => farm)));
  }
}
