import { SettingsActionTypes, SettingsActions } from '../../store/actions/settings.actions';
import * as settingsActions from '../actions/settings.actions';
import { createSelector } from 'reselect';
import { LanguageModel } from 'ag-space-common';

export interface SettingsState {
  languageSelectTouched: boolean;
  loadingLanguages: boolean;
  currentLanguage: LanguageModel;
  rememberedLanguage: LanguageModel;
  availableLanguages: LanguageModel[];
}

export const initialState: SettingsState = {
  languageSelectTouched: false,
  loadingLanguages: false,
  currentLanguage: null,
  rememberedLanguage: null,
  availableLanguages: []
};

export function settingsReducer(state = initialState, action: SettingsActions): SettingsState {

  switch (action.type) {

    case SettingsActionTypes.UPDATE_REMEMBERED_LANGUAGE: {

      const { language } = action as settingsActions.SettingsUpdateRememberedLanguage;
      const languagefromStore = state.availableLanguages.find(l => l.key === language);

      return {
        ...state,
        currentLanguage: languagefromStore,
        rememberedLanguage: languagefromStore
      };
    }

    case SettingsActionTypes.UPDATE_SITE_LANGUAGE: {

      const { language, markTouched } = action as settingsActions.UpdateSiteLanguage;
      const languagefromStore = state.availableLanguages.find(l => l.key === language);

      return {
        ...state,
        currentLanguage: languagefromStore,
        languageSelectTouched: markTouched
      };
    }

    case SettingsActionTypes.GET_AVAILABLE_LANGUAGES: {
      return {
        ...state,
        loadingLanguages: true
      };
    }

    case SettingsActionTypes.GET_AVAILABLE_LANGUAGES_SUCCESS: {
      const { languages } = action as settingsActions.GetAvailableLanguagesSuccess;
      return {
        ...state,
        availableLanguages: languages,
        loadingLanguages: false
      };
    }

    case SettingsActionTypes.GET_AVAILABLE_LANGUAGES_FAILURE: {
      return {
        ...state,
        loadingLanguages: false
      };
    }

    case SettingsActionTypes.SET_USER_LANGUAGE: {
      return {
        ...state,
        loadingLanguages: true
      };
    }

    case SettingsActionTypes.SET_USER_LANGUAGE_SUCCESS: {
      return {
        ...state,
        loadingLanguages: false
      };
    }
    case SettingsActionTypes.SET_USER_LANGUAGE_FAILURE: {
      return {
        ...state,
        loadingLanguages: false
      };
    }

    default: {
      return state;
    }
  }
}

/* Selectors
================ */
export const getSettingsState = (state: SettingsState) => state;
export const getCurrentLanguage = createSelector(getSettingsState, language => language.currentLanguage);
export const getRememberedLanguage = createSelector(getSettingsState, language => language.rememberedLanguage);
export const getAvailableLanguages = createSelector(getSettingsState, language => language.availableLanguages);
export const getLanguagesLoading = createSelector(getSettingsState, language => language.loadingLanguages);
