import { Action } from '@ngrx/store';
import { MapMotionSyncState } from '../reducers/map.reducer';

export enum MapTypes {
  SHOW_LABELS = '[Map] Show Labels',
  HIDE_LABELS = '[Map] Hide Labels',
  CHANGE_VIEWS = '[Maps] Change Views',
  UPDATE_MOTION_SYNC_STATUS = '[Maps] Updated Motion Sync',
  UPDATE_MOTION_SYNC_PARAMS = '[Maps] Update Motion Sync Params'
}

export class ShowLabels implements Action {
    readonly type = MapTypes.SHOW_LABELS;
}
export class HideLabels implements Action {
    readonly type = MapTypes.HIDE_LABELS;
}

export class ChangeViews implements Action {
  readonly type = MapTypes.CHANGE_VIEWS;
  constructor(public views: number) { }
}

export class UpdateMotionSyncStatus implements Action {
  readonly type = MapTypes.UPDATE_MOTION_SYNC_STATUS;
  constructor( public activate: boolean) { }
}

export class UpdateMotionSyncParams implements Action {
  readonly type = MapTypes.UPDATE_MOTION_SYNC_PARAMS;
  constructor(public params: MapMotionSyncState) { }
}

export type All = ShowLabels | HideLabels | ChangeViews | UpdateMotionSyncStatus | UpdateMotionSyncParams;
