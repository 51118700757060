import { Action } from '@ngrx/store';

export enum SeasonTypes {
  UPDATE = '[Season] Update',
  SET_DEFAULT = '[Season] Set Default',
  GET_SEASONS = '[Season] Get',
  GET_SEASONS_SUCCESS = '[Season] Get Success',
  GET_SEASONS_FAILURE = '[Season] Get Failure'
}


export class Update implements Action {
    readonly type = SeasonTypes.UPDATE;
    constructor(public year: number, public season: number) { }
}

export class SetSeasonDefault implements Action {
  readonly type = SeasonTypes.SET_DEFAULT;
  constructor() { }
}

export class GetSeasons implements Action {
  readonly type = SeasonTypes.GET_SEASONS;
  constructor(public distributorId: any) { }
}

export class GetSeasonsSuccess implements Action {
  readonly type = SeasonTypes.GET_SEASONS_SUCCESS;
  constructor(public seasons: number) { }
}

export class GetSeasonsFailure implements Action {
  readonly type = SeasonTypes.GET_SEASONS_FAILURE;
  constructor(public error: any) { }
}

export type All = Update | SetSeasonDefault | GetSeasons | GetSeasonsSuccess | GetSeasonsFailure;
