import { Action } from '@ngrx/store';
import { FarmerModel } from 'ag-space-common';
import { SearchFilterModel } from '../../models/search-filter.model';

export enum FarmersTypes {
  GET_FARMER = '[Farmer] Get',
  GET_FARMER_SUCCESS = '[Farmer] Get Success',
  GET_FARMER_FAILURE = '[Farmer] Get Failure',
  GET_FARMERS = '[Farmers] Get',
  GET_FARMERS_SUCCESS = '[Farmers] Get Success',
  GET_FARMERS_FAILURE = '[Farmers] Get Failure',
  SEARCH_FARMERS = '[Search] Get',
  SET_FARMERS_SORTING = '[Sort] Farmers'
}

export enum SortTypes {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE'
}

export class GetFarmer implements Action {
  readonly type = FarmersTypes.GET_FARMER;
  constructor(public distributorId: any, public farmerId: number) { }
}

export class GetFarmerSuccess implements Action {
  readonly type = FarmersTypes.GET_FARMER_SUCCESS;
  constructor(public farmer: FarmerModel) { }
}

export class GetFarmerFailure implements Action {
  readonly type = FarmersTypes.GET_FARMER_FAILURE;
  constructor(public error: any) { }
}

export class GetFarmers implements Action {
  readonly type = FarmersTypes.GET_FARMERS;
  constructor(public distributorId: any, public year: number, public season: number) { }
}

export class GetFarmersSuccess implements Action {
  readonly type = FarmersTypes.GET_FARMERS_SUCCESS;
  constructor(public farmers: any) { }
}

export class GetFarmersFailure implements Action {
  readonly type = FarmersTypes.GET_FARMERS_FAILURE;
  constructor(public error: any) { }
}

export class SearchFarmers implements Action {
  readonly type = FarmersTypes.SEARCH_FARMERS;
  constructor(public searchObject: SearchFilterModel) { }
}

export class SetFarmersSorting implements Action {
  readonly type = FarmersTypes.SET_FARMERS_SORTING;
  constructor(public cropHealthOrder: SortTypes) { }
}

export type All = GetFarmer | GetFarmerSuccess | GetFarmerFailure | GetFarmers | GetFarmersSuccess | GetFarmersFailure | SearchFarmers | SetFarmersSorting;
