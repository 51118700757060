import { Component, Input, ViewChild, OnChanges } from '@angular/core';
import { AgmInfoWindow } from '@agm/core';
import { ObservationModel, AGSObservationService } from 'ag-space-common';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../core/providers/modal.service';
import { ImageViewComponent } from '../image-modal/image-view.component';

@Component({
    selector: 'maps-observation-info',
    templateUrl: './maps-observation-info.component.html',
    styleUrls: ['./maps-observation-info.component.scss']
})
export class MapsObservationInfoComponent implements OnChanges {
    @Input() observation: any;
    @ViewChild(AgmInfoWindow) infoWindow: AgmInfoWindow;
    cropping: any;
    isOpen = false;
    imageUrl: string = null;

    constructor(
        public translate: TranslateService,
        private observationService: AGSObservationService,
        private modalService: ModalService
    ) { }

    ngOnChanges() {
      if (this.observation) {
            if (this.observation.images && this.observation.images.length > 0) {
              this.observation.images.forEach(item => {
                item['url'] = this.observationService.getObservationImage(this.observation.id, item.id);
              });
            } else {
                this.imageUrl = null;
            }
        } else {
            if (this.isOpen) {
                this.infoWindow.close();
            }
        }
    }

    close() {
      this.infoWindow.close();
    }


  expandImage(image) {
    this.modalService.open( ImageViewComponent, {
      inputs: {
        data: image.url
      },
      outputs: {}
    }, true);
  }

    public open() {
        this.isOpen = true;
        this.infoWindow.open();
    }

    public getCategoryTranslation(name: string) {

        switch (name) {

            case 'Pest':
                return this.translate.instant('MAPS.OBSERVATION_FILTER.PEST');

            case 'Weed':
                return this.translate.instant('MAPS.OBSERVATION_FILTER.WEED');

            case 'Disease':
                return this.translate.instant('MAPS.OBSERVATION_FILTER.DISEASE');

            case 'Other':
                return this.translate.instant('MAPS.OBSERVATION_FILTER.OTHER');

            default:
                return name;


        }

    }
}
