
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
  FieldTypes, GetFieldCropHealth, GetFieldCropHealthFailure, GetFieldCropHealthSuccess
} from '../actions/field.actions';
import { DistributorService } from '../../providers/distributor.service';

@Injectable()
export class FieldEffects {

  @Effect() getFieldCropHealth$ = this.actions$
    .pipe(ofType(FieldTypes.FIELD_CROP_HEALTH),
      switchMap((action: GetFieldCropHealth) => this.distributorService.getFieldCropHealth(action.fieldId).pipe(
      mergeMap(chart => observableOf(new GetFieldCropHealthSuccess(chart))),
      catchError(err => observableOf(new GetFieldCropHealthFailure(err))),)
    ));

  constructor(
    private actions$: Actions,
    private distributorService: DistributorService
  ) {
  }

}
