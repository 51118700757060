import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LanguageModel} from "ag-space-common/dist";

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent implements OnInit {

  public dropdownActive = false;

  @Input() availableLanguages: LanguageModel[];
  @Input() currentLang: LanguageModel;

  @Output() langChanged: EventEmitter<LanguageModel> = new EventEmitter();

  public closeDropdown() {
    this.dropdownActive = false;
  }

  changeLang(lang) {
    this.dropdownActive = false;
    this.langChanged.emit(lang);
  }

  constructor() { }

  ngOnInit() {
  }

}
