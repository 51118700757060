import * as SearchActions from '../actions/search.actions';

export type Action = SearchActions.All;

export interface SearchState {
    farmId: number;
    farmName: string;
}

const defaultState: SearchState = {
    farmId: null,
    farmName: null
};

export function searchReducer (state: SearchState = defaultState, action: Action): SearchState {
    switch (action.type) {

        case SearchActions.FILTER_BY_FARM: {
            return {
                farmId: action.farm.id,
                farmName: action.farm.name
            };
        }

        case SearchActions.CLEAR: {
            return defaultState;
        }

        default: {
            return state;
        }
    }
};
