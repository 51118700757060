import { Action } from '@ngrx/store';

import { NutrientTotalsModel } from '../../models/nutrient-totals.model';

export const GET = '[Nutrients] Get';
export const GET_SUCCESS = '[Nutrients] Get Success';
export const GET_FAILURE = '[Nutrients] Get Failure';
export const SORT = '[Nutrients] Sort';


export class Get implements Action {
    readonly type = GET;
    constructor(public year: number, public season: number) { }
}

export class GetSuccess implements Action {
    readonly type = GET_SUCCESS;
    constructor(public nutrients: NutrientTotalsModel[]) { }
}

export class GetFailure implements Action {
    readonly type = GET_FAILURE;
    constructor(public error: any) { }
}

export class Sort implements Action {
    readonly type = SORT;
    constructor(public column: string, public ascending = true) { }
}

export type All = Get | GetSuccess | GetFailure | Sort;
