
import {of as observableOf,  Observable } from 'rxjs';

import {mergeMap, catchError, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
  GetFarmersSuccess,
  GetFarmersFailure,
  GetFarmers,
  GetFarmer,
  GetFarmerFailure, GetFarmerSuccess, FarmersTypes, SearchFarmers
} from '../actions/farmers.actions';
import { DistributorService } from '../../providers/distributor.service';

@Injectable()
export class FarmersEffects {

  @Effect() getFarmers$ = this.actions$
    .pipe(ofType(FarmersTypes.GET_FARMERS),
      switchMap((action: GetFarmers) => this.distributorService.getFarmers(action.distributorId, action.year, action.season).pipe(
      mergeMap(farmers => observableOf(new GetFarmersSuccess(farmers))),
      catchError(err => observableOf(new GetFarmersFailure(err))),)
    ));

  @Effect() getFarmer$ = this.actions$
    .pipe(ofType(FarmersTypes.GET_FARMER),
      switchMap((action: GetFarmer) => this.distributorService.getFarmer(action.distributorId, action.farmerId).pipe(
      mergeMap(farmers => observableOf(new GetFarmerSuccess(farmers))),
      catchError(err => observableOf(new GetFarmerFailure(err))),)
    ));


  @Effect() getSearchedFarmers$ = this.actions$
    .pipe(ofType(FarmersTypes.SEARCH_FARMERS),
      switchMap((action: SearchFarmers) => this.distributorService.search(action.searchObject).pipe(
      mergeMap(farmers => observableOf(new GetFarmersSuccess(farmers))),
      catchError(err => observableOf(new GetFarmersFailure(err))),)
    ));

  constructor(
    private actions$: Actions,
    private distributorService: DistributorService
  ) {
  }

}
