import { HostListener } from '@angular/core';
import { BoundingRectEvent } from '../../../core/models/bounding-rect-event';
import { Directive, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// Example of usage: <span tooltip content="You can have any html or plain text content here">123 </span>

@Directive({
  selector: '[tooltip]'
})

export class ToolTipDirective {
  @Input() public content: string;
  @Input() public orientation: string;
  @Input() showOnClick = false;
  private maxWidth = 150;
  private padding = 10;

  constructor(@Inject(DOCUMENT) private _document: any) {
  }

  @HostListener('mouseover', ['$event']) onMouseOver(event: any) {
    if (this.showOnClick) {
      return;
    }
    this.showTooltip(event);
  }

  @HostListener('click', ['$event']) onClick(event: any) {
    if (!this.showOnClick) {
      return;
    }
    this.showTooltip(event);
  }

  @HostListener('mouseleave') hideTooltip() {
    let el = this._document.querySelector(`.tooltip__content`);
    if (el) {
      el.parentNode.removeChild(el);
    }
  }

  private showTooltip(event: BoundingRectEvent) {
    if (event.target.attributes['tooltip']) {
      let tooltip = this.buildTooltip(this.content);
      event.target.appendChild(tooltip);
    }
  }

  public buildTooltip(text: string): HTMLElement {
    let node = this._document.createElement('div');                 // Create a <li> node
    node.className = `tooltip__content tooltip__content--${this.orientation || 'bottom'}`;
    this.appendArrow(node);
    this.appendText(node, text);
    node.style.width = Math.min(this.getTextWidth(text), this.maxWidth) + 'px';
    return node;
  }

  private appendArrow(node: HTMLElement) {
    let arrow = this._document.createElement('div');                 // Create a <li> node
    arrow.className = `tooltip__arrow  tooltip__arrow--${this.orientation || 'bottom'}`;
    node.appendChild(arrow);
  }

  private appendText(node: HTMLElement, content: string) {
    let textNode = this._document.createTextNode(content);         // Create a text node
    node.appendChild(textNode);
  }

  private getTextWidth(text) {
    let node = this._document.querySelector('.tooltip__test') || document.createElement('div');                 // Create a <li> node
    node.className = 'tooltip__test';
    this.appendText(node, text);
    let test = this._document.querySelector('body').appendChild(node);
    let width = test.clientWidth + 1;
    test.innerHTML = '';
    return width + this.padding;
  }
}
