import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {State} from '../../core/store/reducers';
import {Store} from '@ngrx/store';
declare let ga; // Declare ga as a function

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {

  constructor(
    public router: Router,
    private store: Store<State>
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  /**
   * Build a unique identifier
   * We probably should just use auth.id until we get to the
   * point where we have the email as a unique identifier at which point send that down as well
   * @param auth
   */
  buildUniqueIdentifier(auth) {
    return auth.id;
  }

  /**
   * When logged in authenticate the user
   * By referencing this function we also get the analytics service to instantiate.
   * The service should only ever construct once as is a singleton.
   */
  updateIfLoggedIn() {
    this.store.select(state => state.auth)
      .subscribe((state) => {
        if (state.loggedIn === true) {
          this.setUserLogin(this.buildUniqueIdentifier(state.user));
        }
      });
  }

  /**
   * Helper function to set the userId
   * @param userId
   */
  setUserLogin(userId) {
    ga('set', 'userId', userId);
  }


  /**
   * Create our event emitter to send our data to Google Analytics
   * @param eventCategory
   * @param eventAction
   * @param eventLabel
   * @param eventValue
   */
  eventEmitter(eventCategory: string,
               eventAction: string,
               eventLabel: string = null,
               eventValue: number = null) {
    ga('send', 'event', {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue
    });
  }
}
