import { Action } from '@ngrx/store';
import { DistributorModel } from 'ag-space-common';
import { UserCredentialsModel } from 'auth-lib';

export enum AuthTypes {
  LOGOUT = '[Auth] Logout',
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  GET_DISTRIBUTORS = '[Distributors] Get',
  GET_DISTRIBUTORS_SUCCESS = '[Distributors] Get Success',
  GET_DISTRIBUTORS_FAILURE = '[Distributors] Get Failure',
  DISTRIBUTOR_UPDATE = '[Distributor] Update',
  GET_CURRENT_USER = '[Auth] Get Current User',
  GET_CURRENT_USER_SUCCESS = '[Auth] Get Current User Success',
  GET_CURRENT_USER_FAILURE = '[Auth] Get Current User Failure'
}

export class Login implements Action {
  readonly type = AuthTypes.LOGIN;
  constructor(public credentials: UserCredentialsModel) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthTypes.LOGIN_SUCCESS;
}

export class LoginFailure implements Action {
  readonly type = AuthTypes.LOGIN_FAILURE;
  constructor(public error: any) {}
}

export class Logout implements Action {
  readonly type = AuthTypes.LOGOUT;
  constructor(public isTokenExpired?: boolean) {}
}

export class GetDistributors implements Action {
  readonly type = AuthTypes.GET_DISTRIBUTORS;
  constructor( public farmerId: number) {}
}
export class GetDistributorsSuccess implements Action {
  readonly type = AuthTypes.GET_DISTRIBUTORS_SUCCESS;
  constructor( public distributors: DistributorModel[]) {}
}
export class GetDistributorsFailure implements Action {
  readonly type = AuthTypes.GET_DISTRIBUTORS_FAILURE;
  constructor(public error: any) {}
}
export class DistributorUpdate implements Action {
  readonly type = AuthTypes.DISTRIBUTOR_UPDATE;
  constructor(public distributor: any) {}
}

export class GetCurrentUser {
  readonly type = AuthTypes.GET_CURRENT_USER;
}
export class GetCurrentUserSuccess {
  readonly type = AuthTypes.GET_CURRENT_USER_SUCCESS;
  constructor(public user: any) {}
}
export class GetCurrentUserFailure {
  readonly type = AuthTypes.GET_CURRENT_USER_FAILURE;
  constructor(public error: any) {}
}

export type All = Logout | Login | LoginSuccess | LoginFailure | GetDistributors |
  GetDistributorsSuccess | GetDistributorsFailure | DistributorUpdate | GetCurrentUser | GetCurrentUserSuccess | GetCurrentUserFailure;
