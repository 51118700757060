import * as MapActions from '../actions/map.actions';
import { MapTypes } from '../actions/map.actions';

export type Action = MapActions.All;

export interface MapState {
    showLabels: boolean;
    views: number;
    motionSync: MapMotionSyncState;
    hideLegendNumbersFor: {
      group: string;
    };
}

export interface MapMotionSyncState {
  enabled: boolean;
  latitude: number;
  longitude: number;
  zoom: number;
}

const defaultState: MapState = {
    showLabels: false,
    views: 1,
    motionSync: {
      enabled: false,
      latitude: null,
      longitude: null,
      zoom: null,
    },
  hideLegendNumbersFor: {
    group: 'growthStage'
  }
};

export function mapReducer (state: MapState = defaultState, action: Action): MapState {
    switch (action.type) {

        case MapTypes.SHOW_LABELS: {
            return {
              ... state,
              showLabels: true
            };
        }

        case MapTypes.HIDE_LABELS: {
            return {
              ...state,
              showLabels: false
            };
        }

      case MapTypes.CHANGE_VIEWS: {
        return {
          ...state,
          views: action.views
        };
      }

      case MapTypes.UPDATE_MOTION_SYNC_STATUS: {
        return {
          ...state,
          motionSync: {
            ...state.motionSync,
            enabled: action.activate
          }
        };
      }

      case MapTypes.UPDATE_MOTION_SYNC_PARAMS: {
        return {
          ...state,
          motionSync: {
            ...state.motionSync,
            ...action.params
          }
        };
      }

        default: {
            return state;
        }
    }
};
