import { Pipe, PipeTransform } from '@angular/core';
import { FarmCropTotalsModel } from '../../core/models/crop-totals.model';

@Pipe({
  name: 'FarmFarmer'
})
export class FarmFarmerPipe implements PipeTransform {
  transform(farms: FarmCropTotalsModel[], searchText: string): any[] {
    if (!searchText || searchText === '') {
      return farms;
    }
    if (!farms) {
      return [];
    }
    searchText = searchText.toLowerCase();
    let farmsFiltered = farms.filter(farm => farm.name.toLowerCase().includes(searchText.trim())
      || farm.farmer.name.toLowerCase().includes(searchText.trim()));
    return farmsFiltered.length > 0 ? farmsFiltered : farms;
  }
}
