import * as currentEnvironment from 'src/environments/current.json';
import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyDatePickerModule } from 'mydatepicker';

import { AgmCoreModule } from '@agm/core';

import { AgSpaceCommonModule } from 'ag-space-common';

import { CoreModule } from './core/core.module';

import { AppComponent } from './app.component';
import { FarmComponent } from './farm/farm.component';
import { GrowersComponent } from './growers/growers.component';
import { CropsComponent } from './crops/crops.component';
import { NutrientsComponent } from './nutrients/nutrients.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { LandingComponent } from './landing/landing.component';

import { FarmSearchComponent } from './shared/components/farm-search/farm-search.component';
import { SeasonSelectComponent } from './shared/components/season-select/season-select.component';
import { NavigationComponent } from './shared/components/navigation/navigation.component';
import { ToolTipDirective } from './shared/components/tooltip/tooltip.directive';

import { routing } from './app.routing';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';

import { ToastrModule } from 'ngx-toastr';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PageNotFoundComponent } from './page-not-found/page-not-found..component';
import { AuthLibModule } from 'auth-lib';
import {SettingsModule} from "./settings/settings.module";
import { RequestInterceptor } from './shared/services/request.interceptor';


const currentEnv: any = currentEnvironment;

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    AuthLibModule.forRoot({
      config: {}
    }),
    SharedModule,
    BrowserModule,
    NoopAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    // Toastr
    ToastrModule.forRoot(),

    AgmCoreModule.forRoot({
      apiKey: currentEnv.googleMapsApiKey
    }),
    AgSpaceCommonModule.forRoot(),
    CoreModule.forRoot(),
    MyDatePickerModule,
    InfiniteScrollModule,
    routing,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SettingsModule
  ],
  declarations: [
    AppComponent,
    FarmComponent,
    GrowersComponent,
    CropsComponent,
    NutrientsComponent,
    FarmSearchComponent,
    SeasonSelectComponent,
    NavigationComponent,
    LoginComponent,
    LogoutComponent,
    LandingComponent,
    ToolTipDirective,
    PageNotFoundComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public appRef: ApplicationRef) {
    this.prodModeCheck();
  }

  prodModeCheck() {
    if (currentEnv.env === 'prod') {
      const script = document.createElement('script');
      script.setAttribute('src', '//rum-static.pingdom.net/pa-5ab53a9f0b3468000700055e.js');
      script.async = true;
      document.body.appendChild(script);
    }
  }
}

