import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AgSpaceCommonModule, AgSpaceConfig } from 'ag-space-common';

import { reducers, metaReducers } from './store/reducers';

import { AuthEffects } from './store/effects/auth.effects';
import { FarmsEffects } from './store/effects/farms.effects';
import { FarmEffects } from './store/effects/farm.effects';
import { CropsEffects } from './store/effects/crops.effects';
import { NutrientsEffects } from './store/effects/nutrients.effects';
import { CGIEffects } from './store/effects/cgi.effects';
import { CatalogueEffects } from './store/effects/catalogue.effects';

import { DistributorService } from './providers/distributor.service';
import { FarmGuardService } from './providers/farm-guard.service';
import { FarmersEffects } from './store/effects/farmers.effects';
import { FieldEffects } from './store/effects/field.effects';
import { ObservationsEffects } from './store/effects/observations.effects';
import { SeasonsEffects } from './store/effects/seasons.effects';
import { AdvancedSearchEffects } from './store/effects/advanced-search.effects';
import { CropColorService } from './providers/crop-color.service';
import { CropNameService } from './providers/crop-name.service';
import { AuthLibModule } from 'auth-lib';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {SettingsEffects} from './store/effects/settings.effects';
import {SettingsService} from './services/settings.service';
import { environment } from '../../environments/environment';

const devtoolsLogOnly = environment.production;

@NgModule({
    imports: [
        AgSpaceCommonModule,
        AuthLibModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreDevtoolsModule.instrument({
          maxAge: 25, // Retains last 25 states
          logOnly: devtoolsLogOnly // Restrict extension to log-only mode
        }),
        EffectsModule.forRoot([
            AuthEffects,
            FarmsEffects,
            FarmEffects,
            CropsEffects,
            NutrientsEffects,
            CGIEffects,
            CatalogueEffects,
            FarmersEffects,
            FieldEffects,
            ObservationsEffects,
            SeasonsEffects,
            SettingsEffects,
            AdvancedSearchEffects
        ]),
    ],
    providers: [
      AuthEffects,
      FarmsEffects,
      DistributorService,
      FarmGuardService,
      CropColorService,
      CropNameService,
      SettingsService
    ]
})
export class CoreModule {
  static forRoot() {
    return {
      ngModule: CoreModule,
    };
  }
}
