import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ZoneModel } from 'ag-space-common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'field-soil-analysis',
  templateUrl: './field-soil-analysis.component.html',
  styleUrls: ['./field-soil-analysis.component.scss']
})
export class FieldSoilAnalysisComponent {

  @Input() fieldId: number;
  @Input() selectedZone;
  @Input() soilReports;
  @Input() organic;
  @Input() zones;

  @Output() zoneClicked: EventEmitter<ZoneModel> = new EventEmitter<ZoneModel>();

  public chemicalMap = {
    'K!!Index': {
      symbol: 'K',
      name: 'Potassium',
      nameTranslated: this.translate.instant('SHARED.POTASSIUM'),
      high: 3,
      low: 2,
      organic_high: 3,
      organic_low: 1.8
    },
    'Mg!!Index': {
      symbol: 'Mg',
      name: 'Magnesium',
      nameTranslated: this.translate.instant('SHARED.MAGNESIUM'),
      high: 3,
      low: 2,
      organic_high: 3,
      organic_low: 2,
    },
    'P!!Index': {
      symbol: 'P',
      name: 'Phosphorus',
      nameTranslated: this.translate.instant('SHARED.PHOSPHORUS'),
      high: 3.3,
      low: 2.3,
      organic_high: 3.3,
      organic_low: 1.8
    },
    'pH!!Other': {
      symbol: 'pH',
      name: 'Acidity',
      nameTranslated: this.translate.instant('SHARED.ACIDITY'),
      high: 7,
      low: 6.5,
      organic_high: 7,
      organic_low: 6.5
    }
  };

  constructor(
    public translate: TranslateService
  )  { }

  selectZone(zone: ZoneModel) {
    this.zoneClicked.emit(zone);
  }

  arrayOfChemicalFindings(zone: ZoneModel) {
    if (!zone || !this.soilReports) {
      return false;

    } else {

      const obj = this.soilReports.zones[zone.id];

      if (obj && Object.keys(obj).length > 0) {
        const keys = Object.keys(obj);
        const finalArr = keys.map((key) => obj[key]);
        return finalArr;
      } else {
        return false;
      }
    }
  }

  soilIndexRule(chemical) {
    const chemicalMapItem = this.chemicalMap[chemical.key];

    if (this.organic) {
      if (chemical.value > chemicalMapItem.organic_high) {
        return 'High';
      } else if (chemical.value < chemicalMapItem.organic_low) {
        return 'Low';
      } else {
        return 'Target';
      }
    } else {
      if (chemical.value > chemicalMapItem.high) {
        return 'High';
      } else if (chemical.value < chemicalMapItem.low) {
        return 'Low';
      } else {
        return 'Target';
      }
    }
  }

  mapRangeTranslation(range: 'High' | 'Low' | 'Target') {
    switch (range) {
      case 'High':
        return this.translate.instant('SHARED.HIGH');

      case 'Low':
        return this.translate.instant('SHARED.LOW');

      case 'Target':
        return this.translate.instant('SHARED.TARGET');
    }
  }

}
