import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DistributorModel } from 'ag-space-common';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../core/store/reducers';

@Component({
    selector: 'distributor-select',
    templateUrl: './distributor-select.component.html'
})
export class DistributorSelectComponent implements OnInit {
    selectedDistributorId$: Observable<number>;
    @Output() selectCrop: EventEmitter<any> = new EventEmitter();
    distributorsList$: Observable<DistributorModel[]>;

    constructor(private store: Store<State>) {}

    ngOnInit() {
      this.distributorsList$ = this.store.select(state => state.auth.distributors);
      this.selectedDistributorId$ = this.store.select(state => state.auth.distributor);
    }

    changeDistributor(distributor) {
      this.selectCrop.emit(distributor);
    }

}
