import { Action } from '@ngrx/store';

export enum FieldTypes {
  FIELD_CROP_HEALTH = '[FIELD CROP HEALTH] Get',
  FIELD_CROP_HEALTH_SUCCESS = '[FIELD CROP HEALTH] Get Success',
  FIELD_CROP_HEALTH_FAILURE = '[FIELD CROP HEALTH] Get Failure'
}

export class GetFieldCropHealth implements Action {
  readonly type = FieldTypes.FIELD_CROP_HEALTH;
  constructor(public fieldId: any) { }
}

export class GetFieldCropHealthSuccess implements Action {
  readonly type = FieldTypes.FIELD_CROP_HEALTH_SUCCESS;
  constructor(public cropHealth: any) { }
}

export class GetFieldCropHealthFailure implements Action {
  readonly type = FieldTypes.FIELD_CROP_HEALTH_FAILURE;
  constructor(public error: any) {}
}

export type All = GetFieldCropHealth | GetFieldCropHealthSuccess | GetFieldCropHealthFailure;
