import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import isNumber from 'lodash-es/isNumber';

@Pipe({
    name: 'safeNumber'
})
export class SafeNumberPipe implements PipeTransform {
    constructor(private decimalPipe: DecimalPipe) {
    }

    transform(value: any, fallback: string, args?: any): any {
        return isNumber(value) ? this.decimalPipe.transform(value, args) : fallback;
    }
}
