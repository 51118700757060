
import {take, combineLatest} from 'rxjs/operators';
import { Component, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject } from 'rxjs';

import { State } from '../../../core/store/reducers';
import { SearchState } from '../../../core/store/reducers/search.reducer';
// import { Get } from '../../../core/store/actions/farms.actions';
import { FilterByFarm, Clear } from '../../../core/store/actions/search.actions';
import { FarmModel } from 'ag-space-common';

@Component({
    selector: 'farm-search',
    templateUrl: './farm-search.component.html',
    styleUrls: ['./farm-search.component.scss']
})
export class FarmSearchComponent implements OnInit {

    @ViewChild('searchField') searchField: ElementRef;
    @Output() onSelect: EventEmitter<any>;

    farms$: Observable<FarmModel[]>;
    results$: Observable<FarmModel[]>;
    search$: Observable<SearchState>;

    searchSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

    searchText = '';
    blurred = true;
    selecting = false;
    items = 0;

    highlighted = 0;

    constructor(
        private store: Store<State>
    ) {}

    ngOnInit() {
        // Get farms if not already obtained
        this.store.select(state => state.farms).pipe(
            take(1))
            .subscribe((state) => {
                if (!state.loading && !state.error && state.farms.length === 0) {
                    // this.store.dispatch(new Get());
                }
            });

        this.search$ = this.store.select(state => state.search);

        this.results$ = this.searchSubject.pipe(combineLatest(
            this.store.select(state => state.farms.farms),
            (search, farms) => {
                if (search.length > 1) {
                    let safe = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
                    let regExp = new RegExp('\\b' + safe, 'i');
                    let items = farms.filter((farm) => {
                        return (farm.name.search(regExp) !== -1 || farm.farmer.name.search(regExp) !== -1);
                    });
                    this.items = items.length;
                    this.highlighted = Math.min(this.highlighted, this.items);
                    return items;
                } else {
                    this.items = 0;
                    this.highlighted = 0;
                    return [];
                }
            }
        ));
    }

    search(value) {
        this.searchText = value;
        console.log(value);

        this.searchSubject.next(value);
    }

    select(item) {
        this.store.dispatch(new FilterByFarm(item));
    }
    clear() {
        this.store.dispatch(new Clear());
        this.searchText = '';
        setTimeout(() => {
            this.searchField.nativeElement.focus();
        }, 1);
    }

    focus() {
        this.blurred = false;
    }
    blur() {
        setTimeout(() => {
            if (!this.selecting) {
                this.blurred = true;
            }
        }, 1);
    }

    clicking() {
        this.selecting = true;
    }
    doneClicking() {
        setTimeout(() => {
            this.selecting = false;
            this.blurred = true;
        }, 1);
    }

    enterSelect() {
        if (this.highlighted > 0) {
            this.results$.pipe(take(1)).subscribe((results) => {
                if (results[this.highlighted - 1]) {
                    this.select(results[this.highlighted - 1]);
                }
            });
        }
    }

    handleArrows(event: KeyboardEvent) {
        switch (event.keyCode) {
            case 38: // Up
                this.highlighted--;
                if (this.highlighted < 0) {
                    this.highlighted = this.items;
                }
                break;
            case 40: // Down
                this.highlighted++;
                if (this.highlighted > this.items) {
                    this.highlighted = 0;
                }
                break;
        }
    }
}
