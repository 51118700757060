import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, combineLatest, map } from 'rxjs/operators';
import { concat, EMPTY, forkJoin, of as observableOf, of, Subject } from 'rxjs';
import { AnalysisService, ApiService } from 'ag-space-common/dist';
import { CatalogueActionTypes, CatalogueLoadSuccess, CatalogueLoadFailure } from '../actions/catalogue.actions';
import { CatalogueLoad } from '../actions/catalogue.actions';

@Injectable()
export class CatalogueEffects {

  @Effect() loadCatalogue$ = this.actions$
    .pipe(ofType(CatalogueActionTypes.GET_CATALOGUE))
    .pipe(
      switchMap((action: CatalogueLoad) => {
        return this.apiService.getCatalogue(action.distributor, 0).pipe(
          mergeMap(catalogue => {
            return observableOf(new CatalogueLoadSuccess(catalogue.data.catalogue));
          }),
          catchError(err => observableOf(new CatalogueLoadFailure(err))),
        )
        }
      )
    );

  constructor(
    private actions$: Actions,
    private analysisService: AnalysisService,
    private apiService: ApiService
  ) { }
}
