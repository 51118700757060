import * as CropsActions from '../actions/crops.actions';
import { CropTotalsModel, FarmCropTotalsModel } from '../../models/crop-totals.model';
import { sort } from '../../../shared/sort';
import { CroppingTypes } from '../actions/crops.actions';

export type Action = CropsActions.All;

export interface CropsState {
    crops: CropTotalsModel[];
    cropsSorted: CropTotalsModel[];
    sortColumn: string;
    sortAsc: boolean;
    loading: boolean;
    error: string;
    cropsByFarm: CropTotalsModel[];
    cropsData: [];
}

const defaultState: CropsState = {
    crops: [],
    cropsSorted: [],
    sortColumn: 'name',
    sortAsc: true,
    loading: false,
    error: null,
    cropsByFarm: [],
    cropsData: []
};

const sortCrops = (crops: CropTotalsModel[], column: string, ascending: boolean): CropTotalsModel[] => {
    let sorted = crops.slice(0).sort((a: CropTotalsModel, b: CropTotalsModel) => {
        switch (column) {
            case 'name':
                return sort(a.crop.name, b.crop.name, ascending);
            case 'area':
                return sort(a.area, b.area, ascending);
            case 'harvest':
                return sort(a.area * a.target, b.area * b.target, ascending);
            case 'yield':
                return sort(a.target, b.target, ascending);
        }
    });

    for (let crop of sorted) {
        if (crop.farms.length > 0) {
            crop.farms = crop.farms.sort((a: FarmCropTotalsModel, b: FarmCropTotalsModel) => {
                switch (column) {
                    case 'name':
                        return sort(a.name, b.name, ascending);
                    case 'area':
                        return sort(a.area, b.area, ascending);
                    case 'harvest':
                        return sort(a.area * a.target, b.area * b.target, ascending);
                    case 'yield':
                        return sort(a.target, b.target, ascending);
                }
            });
        }
    }

    return sorted;
};

export function cropsReducer (state: CropsState = defaultState, action: Action): CropsState {
    switch (action.type) {

        case CroppingTypes.GET: {
            return Object.assign({}, state, {
                crops: [],
                cropsSorted: [],
                loading: true,
                error: null
            });
        }

        case CroppingTypes.GET_SUCCESS: {
            let crops = action.crops;
            return Object.assign({}, state, {
                crops,
                cropsSorted: sortCrops(crops, state.sortColumn, state.sortAsc),
                loading: false,
                error: null
            });
        }

        case CroppingTypes.GET_FAILURE: {
            return Object.assign({}, state, {
                crops: [],
                cropsSorted: [],
                loading: false,
                error: action.error
            });
        }

        case CroppingTypes.SORT: {
            return Object.assign({}, state, {
                cropsSorted: sortCrops(state.crops, action.column, action.ascending),
                sortColumn: action.column,
                sortAsc: action.ascending
            });
        }

      case CroppingTypes.GET_CROPS_BY_FARM: {
        return {
          ...state,
          loading: true
        };
      }

      case CroppingTypes.GET_CROPS_BY_FARM_SUCCESS: {
        return {
          ...state,
          loading: false,
          cropsByFarm: action.cropsByFarmId
        };
      }

      case CroppingTypes.GET_CROPS_BY_FARM_FAILURE: {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }

        default: {
            return state;
        }
    }
};
