import { FarmTypes, All as Action } from '../actions/farm.actions';
import { FarmModel } from 'ag-space-common';

export interface FarmState {
  farm: FarmModel;
  croppingTotals: any;
  loading: boolean;
  error: string;
}

const defaultState: FarmState = {
  farm: null,
  croppingTotals: null,
  loading: false,
  error: null
};

export function farmReducer (state: FarmState = defaultState, action: Action): FarmState {
  switch (action.type) {

    case FarmTypes.GET: {
      return {
        ...state,
        farm: null,
        loading: true,
        error: null
      };
    }

    case FarmTypes.GET_SUCCESS: {
      let farm = action.farm;
      return {
        ...state,
        farm,
        loading: false,
        error: null
      };
    }

    case FarmTypes.GET_FAILURE: {
      return {
        ...state,
        farm: null,
        loading: false,
        error: action.error
      };
    }

    case FarmTypes.GET_CROPPING_TOTALS: {
      return {
        ...state,
        croppingTotals: null,
        loading: true,
        error: null
      };
    }

    case FarmTypes.GET_CROPPING_TOTALS_SUCCESS: {
      let croppingTotals = action.croppingTotals;
      return {
        ...state,
        croppingTotals,
        loading: false,
        error: null
      };
    }

    case FarmTypes.GET_CROPPING_TOTALS_FAILURE: {
      return {
        ...state,
        croppingTotals: null,
        loading: false,
        error: action.error
      };
    }

    default: {
      return state;
    }
  }
};
