
import { of as observableOf } from 'rxjs';

import { catchError, combineLatest, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { SeasonTypes, GetSeasonsSuccess } from '../actions/season.actions';
import { DistributorService } from '../../providers/distributor.service';
import { Store } from '@ngrx/store';
import { State } from '../reducers';

@Injectable()
export class SeasonsEffects {

  @Effect() getSeasonsByDistributor$ = this.actions$
    .pipe(ofType(SeasonTypes.GET_SEASONS))
    .pipe(
      combineLatest(
        this.store.select(state => state.auth.distributor)
      ),
      switchMap((action) => this.distributorService.getSeasons(action[1]).pipe(
        mergeMap(seasons => observableOf(new GetSeasonsSuccess(seasons))),
        catchError(err => observableOf(new GetSeasonsSuccess(err))), )
      )
    );

  constructor(
    private actions$: Actions,
    private distributorService: DistributorService,
    private store: Store<State>,
  ) {
  }

}
