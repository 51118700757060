
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    GET,
    Get,
    GetSuccess,
    GetFailure
} from '../actions/nutrients.actions';
import { DistributorService } from '../../providers/distributor.service';

@Injectable()
export class NutrientsEffects {

    @Effect() get$ = this.actions$
        .pipe(ofType(GET),
            switchMap((action: Get) => this.distributorService.getNutrientTotals(action.year, action.season).pipe(
            mergeMap(nutrients => observableOf(new GetSuccess(nutrients))),
            catchError(err => observableOf(new GetFailure(err))),)
        ));

    constructor(
        private actions$: Actions,
        private distributorService: DistributorService
    ) {
    }

}
