import * as NutrientsActions from '../actions/nutrients.actions';
import { NutrientTotalsModel, FarmNutrientTotalsModel } from '../../models/nutrient-totals.model';
import { sort } from '../../../shared/sort';

export type Action = NutrientsActions.All;

export interface NutrientsState {
    nutrients: NutrientTotalsModel[];
    nutrientsSorted: NutrientTotalsModel[];
    sortColumn: string;
    sortAsc: boolean;
    loading: boolean;
    error: string;
}

const defaultState: NutrientsState = {
    nutrients: [],
    nutrientsSorted: [],
    sortColumn: 'name',
    sortAsc: true,
    loading: false,
    error: null
};

function sortNutrients (nutrients: NutrientTotalsModel[], column: string, ascending: boolean): NutrientTotalsModel[] {
    let sorted = nutrients.slice(0).sort((a: NutrientTotalsModel, b: NutrientTotalsModel) => {
        switch (column) {
            case 'name':
                return sort(a.product.name, b.product.name, ascending);
            case 'area':
                return sort(a.area, b.area, ascending);
            case 'rate':
                return sort(a.plannedRate, b.plannedRate, ascending);
            case 'planned_quantity':
                return sort(a.plannedQuantity, b.plannedQuantity, ascending);
            case 'actual_quantity':
                return sort(a.actualQuantity, b.actualQuantity, ascending);
        }
    });

    for (let nutrient of sorted) {
        if (nutrient.farms.length > 0) {
            nutrient.farms = nutrient.farms.sort((a: FarmNutrientTotalsModel, b: FarmNutrientTotalsModel) => {
                switch (column) {
                    case 'name':
                        return sort(a.name, b.name, ascending);
                    case 'area':
                        return sort(a.area, b.area, ascending);
                    case 'rate':
                        return sort(a.plannedRate, b.plannedRate, ascending);
                    case 'planned_quantity':
                        return sort(a.plannedQuantity, b.plannedQuantity, ascending);
                    case 'actual_quantity':
                        return sort(a.actualQuantity, b.actualQuantity, ascending);
                }
            });
        }
    }

    return sorted;
};

export function nutrientsReducer (state: NutrientsState = defaultState, action: Action): NutrientsState {
    switch (action.type) {

        case NutrientsActions.GET: {
            return Object.assign({}, state, {
                nutrients: [],
                loading: true,
                error: null
            });
        }

        case NutrientsActions.GET_SUCCESS: {
            let nutrients = action.nutrients;
            return Object.assign({}, state, {
                nutrients,
                nutrientsSorted: sortNutrients(nutrients, state.sortColumn, state.sortAsc),
                loading: false,
                error: null
            });
        }

        case NutrientsActions.GET_FAILURE: {
            return Object.assign({}, state, {
                nutrients: [],
                nutrientsSorted: [],
                loading: false,
                error: action.error
            });
        }

        case NutrientsActions.SORT: {
            return Object.assign({}, state, {
                nutrientsSorted: sortNutrients(state.nutrients, action.column, action.ascending),
                sortColumn: action.column,
                sortAsc: action.ascending
            });
        }

        default: {
            return state;
        }
    }
};
