import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  getAvailableLanguages,
  getCurrentLanguage,
  getLanguagesLoading,
  getRememberedLanguage,
  State
} from '../core/store/reducers';
import { Login } from '../core/store/actions/auth.actions';
import { ViewContainerRef } from '@angular/core';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { LanguageModel } from 'ag-space-common';
import * as settingsActions from '../core/store/actions/settings.actions';

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  options: GlobalConfig;

  loading$: Observable<boolean>;
  authSubscription: Subscription;

  currentLanguage$: Observable<LanguageModel>;
  availableLanguages$: Observable<LanguageModel[]>;
  languagesLoading$: Observable<boolean>;
  rememberedLanguage$: Observable<any>;

  constructor(private store: Store<State>,
     public toast: ToastrService,
     vcr: ViewContainerRef) {
    this.options = this.toast.toastrConfig;
    this.setDefaultOptions();

    this.availableLanguages$ = this.store.select(getAvailableLanguages);
    this.currentLanguage$ = this.store.select(getCurrentLanguage);
    this.languagesLoading$ = this.store.select(getLanguagesLoading);
    this.rememberedLanguage$ = this.store.select(getRememberedLanguage);
  }

  changeLanguage(lang: LanguageModel) {
    this.store.dispatch(new settingsActions.UpdateSiteLanguage(lang.key));
    this.store.dispatch(new settingsActions.SettingsUpdateRememberedLanguage(lang.key));
  }

  setDefaultOptions() {
    this.options.positionClass = 'toast-top-center';
    this.options.timeOut = 8000;
    this.options.closeButton = true;
    this.options.enableHtml = true;
    this.options.preventDuplicates = true;
  }

  submit(credentials) {
    this.store.dispatch(new Login(credentials));
  }

  ngOnInit() {
    this.loading$ = this.store.select(store => store.auth.loading);
    this.authSubscription = this.store.select(state => state.auth)
      .subscribe((state) => {
        if (state.error && !state.loading) {
          this.toast.error('The username or password you entered is incorrect. Please try again!');
        }
      });
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }
}
