import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCurrentLanguage, State } from '../../core/store/reducers';
import { mergeMap, take } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/environment.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  variableInit: Promise<any>;

  constructor(
    private store: Store<State>
  ) {
  }

  setUrlVariables(request: HttpRequest<any>) {
    const envVar = request.url.match(/{(.*?)}/);
    if (envVar && envVar.length > 1) {
      return request.url.replace(`{${envVar[1]}}`, EnvironmentService.variables[envVar[1]]);
    }
    return request.url;
  }

  /**
   * This code is a temporary solution until we move away from the old imagery service
   * Until that happens Options request method does not work.
   */
  handleImagery(request, url) {
    const shouldReturn = request.url.match(/@(.*?)@/);
    if (shouldReturn && shouldReturn.length > 1 && shouldReturn[0] === '@return@') {
      const localUrl = url.replace(`@return@`, '');
      return of(new HttpResponse({ status: 200, body: localUrl }));
    }
  }

  /**
   * This intercept handles two bits of functionality this needs clone the request as the request url is read only
   * 1. Updating the template urls from '{env}' to 'dev' for instance
   * 2. Appending on the current language locale to the headers
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers || new HttpHeaders();


    const url = this.setUrlVariables(request);

    const imageryUrl = this.handleImagery(request, url);
    if (imageryUrl) {
      return imageryUrl;
    }

    return this.store.select(getCurrentLanguage).pipe(
      take(1),
      mergeMap((lang) => {
        const tag = lang && lang.tag || 'en';
        headers = headers.append('Accept-Language', tag);
        return next.handle(request.clone({ url, headers }));
      })
    );
  }
}
