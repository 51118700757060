import { Pipe, PipeTransform } from '@angular/core';
import { FarmerModel } from 'ag-space-common';

@Pipe({
  name: 'GrowerName'
})

export class GrowerNamePipe implements PipeTransform {
  transform(farmers: FarmerModel[], searchText: string): any[] {
    if (!searchText || searchText === '') {
      return farmers;
    }
    if (!farmers) {
      return [];
    }
    searchText = searchText.toLowerCase();
    let farmersFiltered = farmers.filter(farmer => farmer.name.toLowerCase().includes(searchText.trim()));
    let result =  farmersFiltered.length > 0 ? farmersFiltered : [];
    return result;
  }
}
