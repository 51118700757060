
import { take, takeUntil, combineLatest,  map } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ObservationModel, FarmModel, GeometryService } from 'ag-space-common';
import { Observable ,  Subject } from 'rxjs';
import { State } from '../core/store/reducers';
import { ShowLabels, HideLabels, ChangeViews, UpdateMotionSyncStatus } from '../core/store/actions/map.actions';
import { ActivatedRoute } from '@angular/router';
import { GetObservations, ResetObservations } from '../core/store/actions/observations.actions';
import { MapsObservationInfoComponent } from '../shared/components/maps-observation-info/maps-observation-info.component';
import { GetCroppingTotals } from '../core/store/actions/farm.actions';
import { GetCropsByFarm } from '../core/store/actions/crops.actions';
import { CropColorService } from '../core/providers/crop-color.service';
import { getYearAndSeason, SeasonState } from '../core/store/reducers/season.reducer';
import 'rxjs/operators/takeUntil';
import 'rxjs/operators/take';
import uniqBy from 'lodash-es/uniqBy';

@Component({
  templateUrl: './farm.component.html',
  styleUrls: ['./farm.component.scss']
})
export class FarmComponent implements OnInit, OnDestroy {

  farmLocation = null;
  loading$: Observable<boolean>;
  seasons$: Observable<SeasonState>;
  farm$: Observable<FarmModel>;
  croppingTotals$: Observable<Object>;
  showLabels$: Observable<boolean>;
  showObservations: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  farmId: number;
  observations$: Observable<ObservationModel[]>;
  croppings = [];
  cropPredictionIds: number[] = [];
  observationsLoading$: Observable<boolean>;
  observationsFiltered$: Observable<ObservationModel[]>;
  showCroppedFields = false;
  views$: Observable<any>;
  viewsArray$;
  motionSync$: Observable<boolean>;
  @ViewChild(MapsObservationInfoComponent) observationInfoWindow: MapsObservationInfoComponent;
  fieldId: string;
  error = false;
  cropZones = {
    zones: null,
    cropLegend: null
  };

  constructor(private store: Store<State>, private route: ActivatedRoute, public cropColorService: CropColorService, private geometry: GeometryService ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.loading$ = this.store.select(state => state.farm.loading);
    this.seasons$ = this.store.select(state => state.season);
    this.store.select(state => state.season).pipe(
      combineLatest(this.route.params, (season, params) => ({params, season})),
      takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        let farmId =  Number(data.params['id']);
        this.store.dispatch(new GetCroppingTotals(farmId, Number(data.season.year), Number(data.season.season)));
        this.store.dispatch(new GetCropsByFarm(farmId, Number(data.season.year), Number(data.season.season)));
        this.showObservation();
      });

    this.farm$ = this.store.select(state => state.farm.farm);
    this.croppingTotals$ = this.store.select(state => state.farm.croppingTotals);
    this.farm$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(farm => {
        this.farmId = farm.id;
        this.farmLocation = {lat: farm.lat, lon: farm.lon};
        if (typeof farm.attributes === 'undefined' || farm.attributes === null || !farm.attributes.length) {
          return;
        }
        if (farm.attributes && farm.attributes.length) {
          farm.attributes.some(attr => {
            if (attr.key === 'iteris_field_id') {
              this.fieldId = attr.value;
              this.error = false;
            } else {
              this.error = true;
            }
          });
        }
      });
    this.showLabels$ = this.store.select(state => state.map.showLabels);
    this.views$ = this.store.select(state => state.map.views);
    this.viewsArray$ = this.store.select(state => state.map.views).pipe(map((n: number) => {
      return Array(n - 1);
    }));
    this.motionSync$ = this.store.select(state => state.map.motionSync.enabled);

    this.geometry.getGeometryByFarmId(this.farmId).pipe(
      take(1))
      .subscribe();

    this.store.select(state => state.crops.cropsByFarm)
      .pipe(combineLatest(this.geometry.zones$, (cropping, zones) => ({cropping, zones})),
      takeUntil(this.ngUnsubscribe), )
      .subscribe(({cropping, zones}) => {
        this.cropZones = this.cropColorService.colorCrops(cropping, zones);
      });

    this.store.select(state => state.crops.cropsByFarm).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(croppings => {
        this.cropPredictionIds = [];
        this.croppings = Object.values(croppings);
        if (this.croppings && this.croppings.length) {
          const filtered = this.croppings.filter(cropping => cropping.plantingDate);
          this.cropPredictionIds = uniqBy(filtered, 'cropId').map(cropping => cropping.cropId);
        }
      });

    this.observations$ = this.store.select(state => state.observations.observations);
    this.observationsLoading$ = this.store.select(state => state.observations.loading);
    this.observationsFiltered$ = this.store.select(state => state.observations.observations).pipe(
      combineLatest( this.store.select(state => state.season),
        this.store.select(state => state.crops.cropsByFarm),
        (observations, season, croppings) => ({observations, season, croppings})),
      map(({observations, season, croppings}) => {
        return observations.filter(observation => {
          let recorded = new Date(observation.recorded),
            recordedSeason = getYearAndSeason(recorded.getFullYear(), recorded.getMonth());
          if (season.seasons.length === 1) {
            return recordedSeason.year === Number(season.year);
          } else {
            return recordedSeason.year === Number(season.year) && recordedSeason.season === Number(season.season);
          }
        }).map(observation => {
          let filteredCroppings =  Object.values(croppings).filter(crop => crop.field.id === observation['field'].id);
          if (!observation['cropping']) {
            observation['cropping'] = {};
          }
          observation['cropping'].cropId = !!filteredCroppings.length ? filteredCroppings[0].cropId : null;
          observation['cropping'].displayName = !!filteredCroppings.length && filteredCroppings[0].assignedCrop ? filteredCroppings[0].assignedCrop.displayName : null;
          return observation;
        });
      }), );
  }

  changeLabelVisibility(show: boolean) {
    if (show) {
      this.store.dispatch(new ShowLabels());
    } else {
      this.store.dispatch(new HideLabels());
    }
  }

  showObservation() {
    if (this.showObservations) {
      this.store.dispatch(new GetObservations(this.farmId));
    } else {
      this.store.dispatch(new ResetObservations());
    }
  }

  viewsChanged(n: number) {
    this.store.dispatch(new ChangeViews(n));
    this.resizeInfoWindow();
  }

  resizeInfoWindow() {
    let elemList = document.getElementsByClassName('gm-style-iw');
    for (let i = 0; i < elemList.length; i++) {
      elemList[i].classList.add('four-active');
    }
    setTimeout(() => {
      for (let i = 0; i < elemList.length; i++) {
        elemList[i].classList.remove('four-active');
      }
    }, 100);
  }

  ngOnDestroy() {
    this.viewsChanged(1);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (this.showObservations) {
      this.store.dispatch(new ResetObservations());
    }
  }


  generateParams(param: Object) {
    return Object.assign({
      dateformat: 'dmy'
    }, param || {});
  }

  handleError() {
    this.error = true;
  }

  checkCropShow(event) {
    this.views$.pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe(view => {
        this.showCroppedFields = view === 1 ? !event.type : this.showCroppedFields;
      });
  }

  updateMotionSync(activate: boolean) {
    this.store.dispatch(new UpdateMotionSyncStatus(activate));
  }

}
