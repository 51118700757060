import { Pipe, PipeTransform } from '@angular/core';
import { CropTotalsModel } from '../../core/models/crop-totals.model';

@Pipe({
  name: 'CropFarmFarmer'
})
export class CropFarmFarmerPipe implements PipeTransform {
  transform(crops: CropTotalsModel[], searchText: string): any[] {
    if (!searchText || searchText === '') {
      return crops;
    }
    if (!crops) {
      return [];
    }
    searchText = searchText.toLowerCase();
    return crops.filter(it => it.crop.name.toLowerCase().includes(searchText.trim()) ||
      it.farms.filter(farm => (farm.name.toLowerCase().includes(searchText.trim()) ||
        farm.farmer.name.toLowerCase().includes(searchText.trim()))).length > 0);
  }
}
