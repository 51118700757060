import { Pipe, PipeTransform } from '@angular/core';

export const sortByName = (list, prop) => {
  if (list && list.length) {
    return list.slice(0).sort((a, b) => {
      const itemA = a[prop].toUpperCase();
      const itemB = b[prop].toUpperCase();
      if (itemA < itemB) { return -1; }
      if (itemA > itemB) { return 1; }
      return 0;
    });
  } else {
    return list;
  }
};

@Pipe({
  name: 'orderBy'
})

export class OrderByPipe implements PipeTransform {
  transform(list: any[], prop: string): any[] {
    return sortByName(list, prop);
  }
}

