import { Action } from '@ngrx/store';
import { FarmModel } from 'ag-space-common';

export const FILTER_BY_FARM = '[Search] Filter by farm';
export const CLEAR = '[Search] Clear';


export class FilterByFarm implements Action {
    readonly type = FILTER_BY_FARM;
    constructor(public farm: FarmModel) { }
}
export class Clear implements Action {
    readonly type = CLEAR;
}

export type All = FilterByFarm | Clear;
