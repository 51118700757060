import '../style/app.scss';
import { TranslateService } from '@ngx-translate/core';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from 'auth-lib';
import {
  getAvailableLanguages,
  getCurrentLanguage,
  getLanguagesLoading,
  getRememberedLanguage,
  State
} from './core/store/reducers';
import { Logout } from './core/store/actions/auth.actions';
import * as settingsActions from './core/store/actions/settings.actions';
import { GoogleAnalyticsService } from './analytics/services/google-analytics.service';

@Component({
  selector: 'my-app', // <my-app></my-app>
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  scrollTop = 100;
  scrollDuration = 400;
  currentLanguage$: Observable<any>;
  availableLanguages$: Observable<any[]>;
  private languagesLoading$: Observable<boolean>;
  private rememberedLanguage$: Observable<any>;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private el: ElementRef,
    private router: Router,
    private store: Store<State>,
    private analytics: GoogleAnalyticsService
  ) {
    translate.setDefaultLang('english');
    this.analytics.updateIfLoggedIn();
    window.addEventListener('scroll', this.scrollFunction.bind(this));

    this.availableLanguages$ = this.store.select(getAvailableLanguages);
    this.currentLanguage$ = this.store.select(getCurrentLanguage);
    this.languagesLoading$ = this.store.select(getLanguagesLoading);
    this.rememberedLanguage$ = this.store.select(getRememberedLanguage);
  }

  ngOnInit() {
    this.authService.onLogout$.subscribe((isTokenExpired: boolean) => {
      this.store.dispatch(new Logout(isTokenExpired));
    });

    this.handleLanguageNotBeingSelected();
    this.setupLanguages();

  }

  /**
   * This handles a case we could occasionally get to where the language was set to null after
   * a user cleared their cache or a redux issue occurred.
   */
  handleLanguageNotBeingSelected() {
    this.currentLanguage$.subscribe((currentLanguage) => {
      if (currentLanguage === null) {
        this.availableLanguages$.subscribe((availableLanguages) => {
          const defaultLanguage = availableLanguages.find((languageOption) => {
            return languageOption.tag === 'en';
          });

          this.store.dispatch(new settingsActions.SetUserLanguage(defaultLanguage));
          this.store.dispatch(new settingsActions.UpdateSiteLanguage(defaultLanguage.key));
          this.store.dispatch(new settingsActions.SettingsUpdateRememberedLanguage(defaultLanguage.key));
        });
      }
    });
  }

  setupLanguages() {
    this.store.select(getCurrentLanguage).subscribe((lang) => {
      if (lang && lang.tag) {
        this.updateLanguageAttribute(lang.tag);
      } else {
        this.updateLanguageAttribute('en');
      }
    });
    this.store.dispatch(new settingsActions.GetAvailableLanguages());
  }

  updateLanguageAttribute(tag: string): void {
    const lang = document.createAttribute('lang');
    lang.value = tag;
    this.el.nativeElement.parentElement.attributes.setNamedItem(lang);
  }

  scrollFunction() {
    if (document.body.scrollTop > this.scrollTop || document.documentElement.scrollTop > this.scrollTop) {
      document.getElementById('goToTopBtn').style.display = 'flex';
    } else {
      document.getElementById('goToTopBtn').style.display = 'none';
    }
  }

  goToTop() {
    let scrollStep = - (window.pageYOffset / (this.scrollDuration / 15)),
      scrollInterval = setInterval(function(){
        if ( window.pageYOffset !== 0 ) {
          window.scrollBy( 0, scrollStep );
        } else {
          clearInterval(scrollInterval);
        }
      }, 15);
  }
}
