import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from '../core/store/reducers';
import { Logout } from '../core/store/actions/auth.actions';

@Component({
    templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

    constructor(private store: Store<State>) {
    }

    ngOnInit() {
        this.store.dispatch(new Logout());
    }
}
